import React from "react";

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = (props) => {
  let dollarUSLocale = Intl.NumberFormat("en-US");
  const { cx, cy, midAngle, outerRadius, fill, payload, customer } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 40;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text
        x={cx}
        y={cy - 50}
        textAnchor='middle'
        fill={fill}
        style={{ fill: "#999999", fontSize: "18px" }}>
        TOTAL PORTFOLIO VALUE
      </text>
      <text
        x={cx}
        y={cy - 15}
        // dy={0}
        textAnchor='middle'
        fill={fill}
        style={{ fill: "#555555", fontSize: "26px" }}>
        ${dollarUSLocale.format(customer?.equityUsd?.totalValue) || "-"}
      </text>

      <text
        x={cx}
        y={cy + 35}
        textAnchor='middle'
        fill={fill}
        style={{ fill: "#999999", fontSize: "18px" }}>
        RETURN OF PERCENTAGE
      </text>
      <text
        x={cx}
        y={cy + 70}
        dy={0}
        textAnchor='middle'
        fill={fill}
        style={{ fill: "#555555", fontSize: "28px" }}>
        {customer?.returnUsd?.totalReturnPercentage?.toFixed(2)} %
      </text>

      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill='none'
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
      <text
        style={{ fontWeight: "bold" }}
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill={fill}>
        {payload.name}
      </text>
      {/* <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill='#999'>
        Value : {value}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey + 20}
        dy={18}
        textAnchor={textAnchor}
        fill='#999'>
        Return :{returnValue}
      </text> */}
    </g>
  );
};

const CustomPieChartLabel = React.memo(renderCustomizedLabel);

export default CustomPieChartLabel;
