import React from "react";

// components
import Admin from "layouts/Admin";
import Calendar from "./Calendar";

export default function Schedule() {
  return (
    <Admin title='Schedule Management'>
      <div className='flex flex-wrap mt-4'>
        <div className='w-full mb-12 px-4'>
          <div
            className={
              "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white p-5"
            }>
            <Calendar />
          </div>
        </div>
      </div>
    </Admin>
  );
}
