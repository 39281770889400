import { Route, Routes } from "react-router-dom";
import Dashboard from "views/admin/Dashboard";
import ManagerDashboard from "views/admin/ManagerDashboard";
import Login from "views/auth/Login";
import Callback from "views/auth/Callback";

import Customer from "views/customer/Customer";
import CustomerDetail from "views/customer/CustomerDetail";
import GlobalNotes from "views/notes/GlobalNotes";

import Schedule from "views/schedule/Schedule";
import { useDispatch } from "react-redux";
import { getProfile, getPermissions } from "store/slice/auth";
import { useEffect } from "react";

import { useCookies } from "react-cookie";

const Platform = () => {
  const dispatch = useDispatch();
  const [cookie] = useCookies();

  useEffect(() => {
    if (cookie?.token) {
      dispatch(getProfile());
      dispatch(getPermissions());
    }
  }, [dispatch, cookie?.token]);

  return (
    <Routes>
      <Route path='/' element={<Login />} />

      <Route path='/callback' element={<Callback />} />
      <Route path='/individual/dashboard' element={<Dashboard />} />
      <Route path='/manager/dashboard' element={<ManagerDashboard />} />
      <Route path='/customer/management' element={<Customer />} />
      <Route
        path='/customer/management/detail/:id'
        element={<CustomerDetail />}
      />
      <Route path='/notes' element={<GlobalNotes />} />

      <Route path='/schedule/management' element={<Schedule />} />
    </Routes>
  );
};

export default Platform;
