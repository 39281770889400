import React from "react";
import GaugeComponent from "react-gauge-component";
import moment from "moment";
import { BounceLoader } from "react-spinners";

export default function GaugeCharts({
  title,
  subTitle,
  data,
  height,
  additional,
  color,
  paddingTop,
  gaugeHeight,
  loading,
}) {
  let dollarUSLocale = Intl.NumberFormat("en-US");

  return (
    <>
      <div className='relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded'>
        <div className='rounded-t mb-0 px-4 py-3 bg-transparent'>
          <div className='flex flex-wrap items-center'>
            <div className='relative w-full max-w-full flex-grow flex-1'>
              <h6 className='uppercase text-blueGray-400 mb-1 text-xs font-semibold'>
                {title}
              </h6>
              <h2 className='text-blueGray-700 text-xl font-semibold'>
                {subTitle}
              </h2>
              <div className='uppercase font-bold text-sm text-blueGray-400'>
                {moment(data?.start_date).format("YYYY-MM-DD")} ~{" "}
                {moment(data?.end_date).format("YYYY-MM-DD")}
              </div>
            </div>
          </div>
        </div>
        <div className='flex-auto'>
          {/* Chart */}
          <div className={`relative h-${height}-px`}>
            {loading ? (
              <div className='flex items-center justify-center h-500-px'>
                <BounceLoader color='#36d7b7' />
              </div>
            ) : (
              <>
                <div
                  style={{
                    height: gaugeHeight + "px",
                    paddingTop: paddingTop + "px",
                  }}>
                  <GaugeComponent
                    type='semicircle'
                    arc={{
                      width: 0.12,
                      padding: 0,
                      cornerRadius: 0,
                      // gradient: true,
                      subArcs: [
                        {
                          limit:
                            data?.current_amount > 0 ? data?.current_amount : 0,
                          color: "green",
                          showTick: true,
                          tooltip: {
                            text: "Current Amount",
                          },
                        },
                        // {
                        //   limit: data?.current_amount * 1.2,
                        //   color: "#bbbbbb",
                        //   showTick: true,
                        //   // tooltip: {
                        //   //   text: "Target Amount",
                        //   // },
                        // },
                        {
                          color: "#bbbbbb",
                          // tooltip: {
                          //   // text: "Too high temperature!",
                          // },
                        },
                      ],
                    }}
                    pointer={{
                      // color: "#345243",
                      color: "#444444",
                      length: 0.6,
                      width: 9,
                      elastic: true,
                    }}
                    labels={{
                      valueLabel: {
                        formatTextValue: (value) =>
                          "$" + dollarUSLocale.format(Number(value)),
                        style: {
                          fontSize: "17px",
                          fill: "#444",
                          textShadow: "none",
                        },
                        hide: true,
                      },
                      tickLabels: {
                        type: "inner",
                        defaultTickValueConfig: {
                          formatTextValue: (value) =>
                            "$" + dollarUSLocale.format(Number(value)),
                          style: {
                            fill: "#444",
                            fontSize: "15px",
                            textShadow: "none",
                          },
                        },
                        defaultTickLineConfig: {
                          char: "_",
                          style: {
                            fill: "#555",
                            fontSize: "20px",
                            textShadow: "none",
                          },
                        },
                      },
                    }}
                    value={data?.current_amount > 0 ? data?.current_amount : 0}
                    minValue={0}
                    maxValue={
                      data?.previous_amount > 0
                        ? Math.max(data?.previous_amount, data?.current_amount)
                        : Math.max(20000, data?.current_amount)
                    }
                  />
                </div>
                <div className='py-5 text-center text-2xl'>
                  $
                  {dollarUSLocale.format(
                    Number(data?.current_amount).toFixed(2)
                  ) || "-"}{" "}
                  (
                  {data?.amount_change_percentage > 0 ? (
                    <span className='font-bold text-green'>
                      {data?.amount_change_percentage?.toFixed(2)}%
                    </span>
                  ) : (
                    <span className='font-bold text-red-500'>
                      {data?.amount_change_percentage?.toFixed(2)}%
                    </span>
                  )}
                  )
                </div>

                {additional && (
                  <div
                    className={`bg-${
                      color || `purple`
                    }-500 wrapper-additional p-5 text-center`}>
                    <div className='text-lg'>{additional}</div>
                    <div className='text-3xl'>
                      {data?.current_count}

                      {data?.count_change_percentage ? (
                        <>
                          (
                          {data?.count_change_percentage > 0 ? (
                            <span className='font-bold text-teal-500'>
                              {data?.count_change_percentage?.toFixed(2)}%
                            </span>
                          ) : (
                            <span className='font-bold text-red-500'>
                              {data?.count_change_percentage?.toFixed(2)}%
                            </span>
                          )}
                          )
                        </>
                      ) : (
                        data?.number_of_customers
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
