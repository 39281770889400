import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// components
import Admin from "layouts/Admin";
import ReactPaginate from "react-paginate";
import { sendGetRequest } from "api/helper";
import { Link } from "react-router-dom";
import LoadingTable from "views/admin/LoadingTable";
import { useSelector } from "react-redux";
import { selectProfile } from "store/slice/auth";

export default function Customer({ color }) {
  const { profile } = useSelector(selectProfile);

  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [orderBy, setOrderBy] = useState("id");

  const [tempId, setTempId] = useState("");
  const [tempName, setTempName] = useState("");
  const [tempAccountNumber, setTempAccountNumber] = useState("");

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");

  const limit = 30;

  let dollarUSLocale = Intl.NumberFormat("en-US");

  useEffect(() => {
    setLoading(true);
    async function getData() {
      const result = await sendGetRequest(
        `/api/v1/rms/me/customers?page=${page}&limit=${limit}&id=${id}&name=${name}&gotradeAccountNumber=${accountNumber}&orderBy=${orderBy}`
      );
      setCustomers(result);
      setLoading(false);
    }
    getData();
  }, [page, orderBy, id, name, accountNumber]);

  const onPageChange = async (e) => {
    setPage(parseInt(e.selected + 1));
  };

  const handleFilter = async (e) => {
    e.preventDefault();
    setId(tempId);
    setName(tempName);
    setAccountNumber(tempAccountNumber);
    setPage(1);
  };

  return (
    <Admin title='Customer Management'>
      <div className='flex flex-wrap mt-4'>
        <div className='w-full mb-12 px-4'>
          <div
            className={
              "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
              (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
            }>
            <div className='rounded-t mb-0 px-4 py-3 border-0'>
              <div className='flex flex-wrap items-center'>
                <div className='relative w-full px-2 max-w-full flex-grow flex-1'>
                  <h3
                    className={
                      "font-semibold text-lg " +
                      (color === "light" ? "text-blueGray-700" : "text-white")
                    }>
                    Customer Lists
                  </h3>
                </div>

                <div className='relative w-full px-2 max-w-full flex-grow flex-1'>
                  <form onSubmit={handleFilter} className='flex'>
                    <div className='relative flex w-full flex-wrap items-stretch'>
                      <input
                        onChange={(e) => setTempId(e.target.value)}
                        value={tempId}
                        style={{ width: "100px" }}
                        type='text'
                        placeholder='Search ID'
                        className='mr-3 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-3'
                      />
                    </div>

                    <div className='relative flex w-full flex-wrap items-stretch'>
                      <input
                        onChange={(e) => setTempName(e.target.value)}
                        value={tempName}
                        style={{ width: "200px" }}
                        type='text'
                        placeholder='Search Name'
                        className='mr-3 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-3'
                      />
                    </div>

                    <div className='relative flex w-full flex-wrap items-stretch'>
                      <input
                        onChange={(e) => setTempAccountNumber(e.target.value)}
                        value={tempAccountNumber}
                        style={{ width: "250px" }}
                        type='text'
                        placeholder='Search GOTrade Account Number'
                        className='mr-3 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-3'
                      />
                    </div>

                    <button
                      type='submit'
                      className='bg-indigo-500 text-white active:bg-indigo-600 text-sm font-bold uppercase px-3 py-2 rounded outline-none focus:outline-none ease-linear transition-all duration-150'>
                      Search
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className='block w-full overflow-x-auto'>
              <table className='items-center w-full bg-transparent border-collapse'>
                <thead>
                  <tr>
                    {profile?.permissions?.find(
                      (item) => item?.permission === "read:rm_customer_profile"
                    ) && (
                      <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          (color === "light"
                            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                        }
                      />
                    )}
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                        (color === "light"
                          ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                          : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                      }>
                      ID
                      {orderBy === "-id" ? (
                        <i
                          className='ml-3 fa fa-chevron-down cursor-pointer'
                          onClick={() => setOrderBy("id")}
                        />
                      ) : orderBy === "id" ? (
                        <i
                          className='ml-3 fa fa-chevron-up cursor-pointer'
                          onClick={() => setOrderBy("-id")}
                        />
                      ) : (
                        <i
                          className='ml-3 fa fa-chevron-up cursor-pointer opacity-50'
                          onClick={() => setOrderBy("-id")}
                        />
                      )}
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                        (color === "light"
                          ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                          : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                      }>
                      Name
                      {orderBy === "-name" ? (
                        <i
                          className='ml-3 fa fa-chevron-down cursor-pointer'
                          onClick={() => setOrderBy("name")}
                        />
                      ) : orderBy === "name" ? (
                        <i
                          className='ml-3 fa fa-chevron-up cursor-pointer'
                          onClick={() => setOrderBy("-name")}
                        />
                      ) : (
                        <i
                          className='ml-3 fa fa-chevron-up cursor-pointer opacity-50'
                          onClick={() => setOrderBy("-name")}
                        />
                      )}
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                        (color === "light"
                          ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                          : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                      }>
                      GoTrade Account Number
                      {orderBy === "-gotradeAccountNumber" ? (
                        <i
                          className='ml-3 fa fa-chevron-down cursor-pointer'
                          onClick={() => setOrderBy("gotradeAccountNumber")}
                        />
                      ) : orderBy === "gotradeAccountNumber" ? (
                        <i
                          className='ml-3 fa fa-chevron-up cursor-pointer'
                          onClick={() => setOrderBy("-gotradeAccountNumber")}
                        />
                      ) : (
                        <i
                          className='ml-3 fa fa-chevron-up cursor-pointer opacity-50'
                          onClick={() => setOrderBy("-gotradeAccountNumber")}
                        />
                      )}
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                        (color === "light"
                          ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                          : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                      }>
                      Phone Number
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                        (color === "light"
                          ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                          : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                      }>
                      Portfolio Value (USD)
                      {orderBy === "-equityUsd" ? (
                        <i
                          className='ml-3 fa fa-chevron-down cursor-pointer'
                          onClick={() => setOrderBy("equityUsd")}
                        />
                      ) : orderBy === "equityUsd" ? (
                        <i
                          className='ml-3 fa fa-chevron-up cursor-pointer'
                          onClick={() => setOrderBy("-equityUsd")}
                        />
                      ) : (
                        <i
                          className='ml-3 fa fa-chevron-up cursor-pointer opacity-50'
                          onClick={() => setOrderBy("-equityUsd")}
                        />
                      )}
                    </th>
                  </tr>
                </thead>
                {loading ? (
                  <LoadingTable row={limit - 1} column={8} />
                ) : (
                  <tbody>
                    {customers?.data?.length > 0
                      ? customers?.data?.map((data, index) => (
                          <tr key={index}>
                            {profile?.permissions?.find(
                              (item) =>
                                item?.permission === "read:rm_customer_profile"
                            ) && (
                              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-3 text-right'>
                                <Link
                                  to={`/customer/management/detail/${data.id}`}>
                                  <i className='fas fa-external-link-alt text-teal-500 pointer' />
                                </Link>
                              </td>
                            )}
                            <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-3'>
                              <div className='max-w-120-px text-ellipsis'>
                                {data?.id}
                              </div>
                            </td>
                            <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-3'>
                              <div className='max-w-120-px text-ellipsis'>
                                {data?.profile?.name}
                              </div>
                            </td>
                            <th className='border-t-0 px-6 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-3 text-left flex items-center'>
                              <span
                                className={
                                  "font-bold " +
                                  +(color === "light"
                                    ? "text-blueGray-600"
                                    : "text-white")
                                }>
                                {data?.profile?.gotradeAccountNumber}
                              </span>
                            </th>
                            <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-3'>
                              {data?.profile?.phoneNumber}
                            </td>
                            <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 border-b font-bold text-sm whitespace-nowrap p-3'>
                              {data?.equityUsd?.currency === "USD" && "$"}
                              {dollarUSLocale.format(
                                data?.equityUsd?.totalValue
                              ) || "-"}
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                )}
              </table>

              <ReactPaginate
                previousLabel={"<"}
                disabledClassName={"disabled"}
                nextLabel={">"}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                marginPagesDisplayed={5}
                pageRangeDisplayed={1}
                pageCount={Math.ceil(customers?.totalRecords / limit)}
                onPageChange={onPageChange}
                // forcePage={page}
              />
            </div>
          </div>
        </div>
      </div>
    </Admin>
  );
}

Customer.defaultProps = {
  color: "light",
};

Customer.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
