import React from "react";

export default function IdrSummary({ customer }) {
  let dollarUSLocale = Intl.NumberFormat("de-DE");
  let percentageLocale = Intl.NumberFormat("en-US");

  return (
    <>
      <div className='flex flex-wrap mt-5'>
        <div className='w-full lg:w-6/12 px-6'>
          <div className='bg-purple-500 wrapper p-5 mb-4'>
            <i className='fa fa-wallet mr-3' />{" "}
            <span className='uppercase font-bold'>Cash</span>
            <div className='mt-3'>
              <div className='my-2'>
                <label
                  className='block uppercase text-white text-xs font-bold mb-1'
                  htmlFor='grid-password'>
                  Cash Balance :
                </label>
                <label className='text-xl'>
                  Rp{" "}
                  {dollarUSLocale.format(
                    Number(customer?.cashIdr?.cashBalance).toFixed(0)
                  ) || "-"}
                </label>
              </div>
              <div className='my-2'>
                <label
                  className='block uppercase text-white text-xs font-bold mb-1'
                  htmlFor='grid-password'>
                  Cash Available For Trade :
                </label>
                <label className='text-xl'>
                  Rp{" "}
                  {dollarUSLocale.format(
                    Number(customer?.cashIdr?.cashAvailableForTrade).toFixed(0)
                  ) || "-"}
                </label>
              </div>
              <div className='my-2'>
                <label
                  className='block uppercase text-white text-xs font-bold mb-1'
                  htmlFor='grid-password'>
                  Cash Available For Withdrawal :
                </label>
                <label className='text-xl'>
                  Rp{" "}
                  {dollarUSLocale.format(
                    Number(
                      customer?.cashIdr?.cashAvailableForWithdrawal
                    ).toFixed(0)
                  ) || "-"}
                </label>
              </div>
            </div>
          </div>

          <div className='bg-purple-500 wrapper p-5 mb-4'>
            <div>
              <i className='fa fa-undo mr-3' />{" "}
              <span className='uppercase font-bold'>Return</span>
              <div className='mt-2 mb-3'>
                <label
                  className='block uppercase text-white text-xs font-bold mb-1'
                  htmlFor='grid-password'>
                  Return Amount :
                </label>
                <label className='text-xl'>
                  Rp{" "}
                  {dollarUSLocale.format(
                    Number(customer?.returnIdr?.totalReturn).toFixed(0)
                  ) || "-"}
                </label>
              </div>
              <div className='mb-1'>
                <label
                  className='block uppercase text-white text-xs font-bold mb-1'
                  htmlFor='grid-password'>
                  Return Percentage :
                </label>
                <label className='text-xl'>
                  {percentageLocale.format(
                    Number(customer?.returnIdr?.totalReturnPercentage).toFixed(
                      2
                    )
                  ) || "-"}
                  %
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className='w-full lg:w-6/12 px-6'>
          <div className='bg-indigo-500 wrapper p-5'>
            <i className='fa fa-money-check mr-3' />{" "}
            <span className='uppercase font-bold text-lg'>
              Funding Activity
            </span>
            <div className='mt-8'>
              <label
                className='block uppercase text-md font-bold mb-1 text-white'
                htmlFor='grid-password'>
                Deposit
              </label>
              <div className='mb-3'>
                <label
                  className='uppercase text-white text-xs font-bold mb-2'
                  htmlFor='grid-password'>
                  Total Amount :
                </label>
                <label className='ml-2'>
                  Rp{" "}
                  {dollarUSLocale.format(
                    Number(customer?.depositsIdr?.totalAmount).toFixed(0)
                  ) || "-"}
                </label>
              </div>
              <div className='mt-8'>
                <label
                  className='block uppercase text-md font-bold mb-1 text-white'
                  htmlFor='grid-password'>
                  Withdrawals
                </label>
                <div className='mb-12'>
                  <label
                    className='uppercase text-white text-xs font-bold mb-2'
                    htmlFor='grid-password'>
                    Total Amount :
                  </label>
                  <label className='ml-2'>
                    Rp{" "}
                    {dollarUSLocale.format(
                      Number(customer?.withdrawalsIdr?.totalAmount).toFixed(0)
                    ) || "-"}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='flex flex-wrap mt-5 bg-emerald-500 wrapper mx-6 mb-8'>
        <div className='w-full lg:w-12/12 px-6 mt-3'>
          <i className='fa fa-list mr-3' />{" "}
          <span className='uppercase font-bold text-lg'>Portfolio</span>
        </div>

        <div className='w-full lg:w-4/12 px-4'>
          <div className='mt-4'>
            <label
              className='block uppercase text-md font-bold mb-2 text-white'
              htmlFor='grid-password'>
              Equity
            </label>
            <div className='mb-4'>
              <label
                className='uppercase text-white text-xs font-bold mb-2'
                htmlFor='grid-password'>
                Total Value :
              </label>
              <label className='ml-2'>
                Rp{" "}
                {dollarUSLocale.format(
                  Number(customer?.equityIdr?.totalValue).toFixed(0)
                ) || "-"}
              </label>
            </div>
          </div>
        </div>

        <div className='w-full lg:w-4/12 px-4'>
          <div className='mt-4'>
            <label
              className='block uppercase text-md font-bold mb-2 text-white'
              htmlFor='grid-password'>
              Stocks
            </label>

            <div className='mb-4'>
              <label
                className='uppercase text-white text-xs font-bold mb-2'
                htmlFor='grid-password'>
                Total Value :
              </label>
              <label className='ml-2'>
                Rp{" "}
                {dollarUSLocale.format(
                  Number(customer?.equityIdr?.stocks?.totalValue).toFixed(0)
                ) || "-"}
              </label>
            </div>
          </div>
        </div>

        <div className='w-full lg:w-4/12 px-4'>
          <div className='mt-4'>
            <label
              className='block uppercase text-md font-bold mb-2 text-white'
              htmlFor='grid-password'>
              Cash
            </label>

            <div className='mb-4'>
              <label
                className='uppercase text-white text-xs font-bold mb-2'
                htmlFor='grid-password'>
                Cash Balance :
              </label>
              <label className='ml-2'>
                Rp{" "}
                {dollarUSLocale.format(
                  Number(customer?.equityIdr?.cash?.cashBalance).toFixed(0)
                ) || "-"}
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
