import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

import styles from "./styles/Login.module.scss";
import { PuffLoader } from "react-spinners";
import { useSelector } from "react-redux";
import { selectProfile } from "store/slice/auth";

export default function Callback() {
  const navigate = useNavigate();
  const { profile } = useSelector(selectProfile);
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [, setCookie] = useCookies();

  useEffect(() => {
    const getData = async () => {
      const accessToken = await getAccessTokenSilently();
      setCookie("token", accessToken, { path: "/", expires: "" });
    };

    if (isAuthenticated) {
      getData();
      if (
        profile?.permissions?.find(
          (item) => item?.permission === "read:manager_rms_metrics"
        )
      ) {
        navigate("/manager/dashboard");
      } else if (
        profile?.permissions?.find(
          (item) => item?.permission === "read:rm_metrics"
        )
      ) {
        navigate("/individual/dashboard");
      }
    }
  }, [
    getAccessTokenSilently,
    isAuthenticated,
    navigate,
    setCookie,
    profile?.permissions,
  ]);

  return (
    <>
      {isLoading && (
        <div className={styles.loadingWrapper}>
          <div>
            <PuffLoader color='#36d7b7' size='100' className={styles.loader} />
            <div className={styles.spinners}>Loading</div>
          </div>
        </div>
      )}
    </>
  );
}
