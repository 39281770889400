import React, { useEffect, useState } from "react";

// components
import Admin from "layouts/Admin";
import { sendGetRequest } from "api/helper";
import ComposedCharts from "./Charts/ComposedCharts";
import TableNetDeposits from "./Charts/TableNetDeposits";
import TableDeposits from "./Charts/TableDeposits";
import TableWithdrawals from "./Charts/TableWithdrawals";

import TablePortfolio from "./Charts/TablePortfolio";
import TableBuyTrades from "./Charts/TableBuyTrades";
import TableSellTrades from "./Charts/TableSellTrades";

import TableRevenues from "./Charts/TableRevenues";

import TableChurns from "./Charts/TableChurns";
import TableCustomerSessions from "./Charts/TableCustomerSessions";

export default function Dashboard() {
  const [allData, setAllData] = useState("");
  // 1
  const [netDeposits, setNetDeposits] = useState("");
  const [deposits, setDeposits] = useState("");
  const [depositsCount, setDepositsCount] = useState("");
  const [withdrawals, setWithrawals] = useState("");
  const [withdrawalsCount, setWithrawalsCount] = useState("");

  // 2
  const [portfolio, setPortfolio] = useState("");
  const [buyTrades, setBuyTrades] = useState("");
  const [buyTradesCount, setBuyTradesCount] = useState("");
  const [sellTrades, setSellTrades] = useState("");
  const [sellTradesCount, setSellTradesCount] = useState("");

  // 3
  const [revenue, setRevenue] = useState("");

  // 4
  const [churns, setChurns] = useState("");
  const [customerSessions, setCustomerSessions] = useState("");

  const [tab, setTab] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getData() {
      const allData = await sendGetRequest(
        `/api/v1/managers/me/rms_metrics/latest_weekly_output_metrics`
      );
      setAllData(allData);

      if (tab === 1) {
        setLoading(true);
        const netDeposits = await sendGetRequest(
          `/api/v1/managers/me/rms_metrics/latest_weekly_net_deposits?limit=${15}&page=${1}&orderBy=-weekly_net_deposit_value_usd`
        );
        setNetDeposits(netDeposits?.data);

        const deposits = await sendGetRequest(
          `/api/v1/managers/me/rms_metrics/latest_weekly_deposits?limit=${15}&page=${1}&orderBy=-weekly_deposit_value_usd`
        );
        setDeposits(deposits?.data);

        const depositsCount = await sendGetRequest(
          `/api/v1/managers/me/rms_metrics/latest_weekly_deposits?limit=${15}&page=${1}&orderBy=-count_weekly_deposits`
        );
        setDepositsCount(depositsCount?.data);

        const withdrawals = await sendGetRequest(
          `/api/v1/managers/me/rms_metrics/latest_weekly_withdrawals?limit=${15}&page=${1}&orderBy=-weekly_withdrawal_value_usd`
        );
        setWithrawals(withdrawals?.data);

        const withdrawalsCount = await sendGetRequest(
          `/api/v1/managers/me/rms_metrics/latest_weekly_withdrawals?limit=${15}&page=${1}&orderBy=-count_weekly_withdrawals`
        );
        setWithrawalsCount(withdrawalsCount?.data);
        setLoading(false);
      } else if (tab === 2) {
        setLoading(true);
        const portfolio = await sendGetRequest(
          `/api/v1/managers/me/rms_metrics/latest_weekly_portfolio?limit=${15}&page=${1}&orderBy=-portfolio_value_usd_end_of_week`
        );
        setPortfolio(portfolio?.data);

        const buyTrades = await sendGetRequest(
          `/api/v1/managers/me/rms_metrics/latest_weekly_buy_trades?limit=${15}&page=${1}&orderBy=-weekly_buy_trade_value_usd`
        );
        setBuyTrades(buyTrades?.data);

        const buyTradesCount = await sendGetRequest(
          `/api/v1/managers/me/rms_metrics/latest_weekly_buy_trades?limit=${15}&page=${1}&orderBy=-count_weekly_buy_trades`
        );
        setBuyTradesCount(buyTradesCount?.data);

        const sellTrades = await sendGetRequest(
          `/api/v1/managers/me/rms_metrics/latest_weekly_sell_trades?limit=${15}&page=${1}&orderBy=-weekly_sell_trade_value_usd`
        );
        setSellTrades(sellTrades?.data);

        const sellTradesCount = await sendGetRequest(
          `/api/v1/managers/me/rms_metrics/latest_weekly_sell_trades?limit=${15}&page=${1}&orderBy=-count_weekly_sell_trades`
        );
        setSellTradesCount(sellTradesCount?.data);
        setLoading(false);
      } else if (tab === 3) {
        setLoading(true);
        const revenue = await sendGetRequest(
          `/api/v1/managers/me/rms_metrics/latest_weekly_revenue?limit=${15}&page=${1}&orderBy=-weekly_total_revenue_usd`
        );
        setRevenue(revenue?.data);
        setLoading(false);
      } else if (tab === 4) {
        setLoading(true);
        const churns = await sendGetRequest(
          `/api/v1/managers/me/rms_metrics/latest_weekly_churn?limit=${15}&page=${1}&orderBy=-total_churn_users`
        );
        setChurns(churns?.data);

        const customerSessions = await sendGetRequest(
          `/api/v1/managers/me/rms_metrics/latest_weekly_customer_session?limit=${15}&page=${1}&orderBy=-avg_weekly_session_length_second`
        );
        setCustomerSessions(customerSessions?.data);
        setLoading(false);
      }
    }
    getData();
  }, [tab]);

  return (
    <Admin title='Dashboard'>
      <div className='w-full mb-12 px-4 h-full-80'>
        <div
          className={
            "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
            "bg-white"
          }>
          <div className='rounded-t mb-0 py-3 pb-0 border-0 border-b'>
            <div className='flex flex-wrap items-center'>
              <div className='relative w-full max-w-full flex-grow flex-1'>
                <div className='flex px-4'>
                  <div
                    className={`tabs ${tab === 1 && "active"}`}
                    onClick={() => setTab(1)}>
                    Net Deposits
                  </div>
                  <div
                    className={`tabs ${tab === 2 && "active"}`}
                    onClick={() => setTab(2)}>
                    Portfolio
                  </div>
                  <div
                    className={`tabs ${tab === 3 && "active"}`}
                    onClick={() => setTab(3)}>
                    Revenues
                  </div>
                  <div
                    className={`tabs ${tab === 4 && "active"}`}
                    onClick={() => setTab(4)}>
                    Activities
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='block w-full overflow-x-auto'>
            {tab === 1 && (
              <div className='flex flex-wrap my-4'>
                {/* NET DEPOSITS */}
                <div className='w-full lg:w-12/12 mb-12 xl:mb-0 px-2'>
                  <ComposedCharts
                    title='Net Deposit Amount'
                    subTitle='Top Weekly Net Deposit Amount'
                    height={600}
                    data={netDeposits}
                    allData={netDeposits}
                    bar='current_amount'
                    additional='Total Net Deposit Amount'
                    color='indigo'
                    loading={loading}
                  />
                </div>
                <div className='w-full lg:w-12/12 mt-8 mb-12 xl:mb-0 px-2'>
                  <TableNetDeposits
                    title='Weekly Net Deposits'
                    subTitle='All Weekly Net Deposits'
                    height={500}
                  />
                </div>

                {/* DEPOSITS */}
                <div className='w-full lg:w-6/12 mb-12 xl:mb-0 px-2'>
                  <ComposedCharts
                    title='Deposit Amount'
                    subTitle='Top Weekly Deposit Amount'
                    height={600}
                    data={deposits}
                    allData={allData?.deposits}
                    bar='current_amount'
                    additional='Total Deposit Amount'
                    color='indigo'
                    loading={loading}
                  />
                </div>
                <div className='w-full lg:w-6/12 mb-12 xl:mb-0 px-2'>
                  <ComposedCharts
                    title='Deposit Count'
                    subTitle='Top Weekly Deposit Count'
                    height={600}
                    data={depositsCount}
                    allData={allData?.deposits}
                    bar='current_count'
                    additional='Total Deposit Count'
                    color='indigo'
                  />
                </div>
                <div className='w-full lg:w-12/12 mt-8 mb-12 xl:mb-0 px-2'>
                  <TableDeposits
                    title='Weekly Deposit Amount'
                    subTitle='All Weekly Deposit Amount'
                    height={550}
                  />
                </div>

                {/* WITHDRAWALS AMOUNT */}
                <div className='w-full lg:w-6/12 mb-12 xl:mb-0 px-2'>
                  <ComposedCharts
                    title='Withdrawal Amount'
                    subTitle='Top Weekly Withdrawal Amount'
                    height={600}
                    data={withdrawals}
                    allData={allData?.withdrawals}
                    bar='current_amount'
                    additional='Total Withdrawal Amount'
                    color='indigo'
                    loading={loading}
                  />
                </div>
                <div className='w-full lg:w-6/12 mb-12 xl:mb-0 px-2'>
                  <ComposedCharts
                    title='Withdrawal Count'
                    subTitle='Top Weekly Withdrawal Count'
                    height={600}
                    data={withdrawalsCount}
                    allData={allData?.withdrawals}
                    bar='current_count'
                    additional='Total Withdrawal Count'
                    color='indigo'
                    loading={loading}
                  />
                </div>
                <div className='w-full lg:w-12/12 mt-8 mb-12 xl:mb-0 px-2'>
                  <TableWithdrawals
                    title='Weekly Withdrawals'
                    subTitle='All Weekly Withdrawals'
                    height={550}
                  />
                </div>
              </div>
            )}

            {tab === 2 && (
              <div className='flex flex-wrap my-4'>
                {/* PORTFOLIO AMOUNT */}
                <div className='w-full lg:w-12/12 mb-12 xl:mb-0 px-2'>
                  <ComposedCharts
                    title='Portfolio Amount'
                    subTitle='Top Weekly Portfolio Amount'
                    height={600}
                    data={portfolio}
                    allData={allData?.portfolios}
                    bar='current_amount'
                    additional='Total Portfolio Amount'
                    loading={loading}
                  />
                </div>
                <div className='w-full lg:w-12/12 mb-12 xl:mb-0 px-2'>
                  <TablePortfolio
                    title='Weekly Portfolio'
                    subTitle='All Weekly Portfolio'
                    height={500}
                  />
                </div>

                {/* BUY TRADES AMOUNT */}
                <div className='w-full lg:w-6/12 mb-12 xl:mb-0 px-2'>
                  <ComposedCharts
                    title='Buy Trade Amount'
                    subTitle='Top Weekly Buy Trade Amount'
                    height={600}
                    data={buyTrades}
                    allData={allData?.buy_trades}
                    bar='current_amount'
                    additional='Total Weekly Buy Trade Amount'
                    loading={loading}
                  />
                </div>
                <div className='w-full lg:w-6/12 px-2'>
                  <ComposedCharts
                    title='Buy Trade Count'
                    subTitle='Top Weekly Buy Trade Count'
                    height={600}
                    data={buyTradesCount}
                    allData={allData?.buy_trades}
                    bar='current_count'
                    additional='Total Weekly Buy Trade Count'
                  />
                </div>
                <div className='w-full lg:w-12/12 mt-8 mb-12 xl:mb-0 px-2'>
                  <TableBuyTrades
                    title='Weekly Buy Trades'
                    subTitle='All Weekly Buy Trades'
                    height={550}
                  />
                </div>

                {/* SELL TRADES AMOUNT */}
                <div className='w-full lg:w-6/12 mb-12 xl:mb-0 px-2'>
                  <ComposedCharts
                    title='Sell Trade Amount'
                    subTitle='Top Weekly Sell Trade Amount'
                    height={600}
                    data={sellTrades}
                    allData={allData?.sell_trades}
                    bar='current_amount'
                    additional='Total Weekly Sell Trade Amount'
                    loading={loading}
                  />
                </div>
                <div className='w-full lg:w-6/12 px-2'>
                  <ComposedCharts
                    title='Sell Trade Count'
                    subTitle='Top Weekly Sell Trade Count'
                    height={600}
                    data={sellTradesCount}
                    allData={allData?.sell_trades}
                    bar='current_count'
                    additional='Total Weekly Sell Trade Count'
                    loading={loading}
                  />
                </div>
                <div className='w-full lg:w-12/12 mt-8 mb-12 xl:mb-0 px-2'>
                  <TableSellTrades
                    title='Weekly Sell Trade'
                    subTitle='Weekly Sell Trade'
                    height={550}
                  />
                </div>
              </div>
            )}

            {tab === 3 && (
              <div className='flex flex-wrap my-4'>
                {/* REVENUES COUNT */}
                <div className='w-full lg:w-12/12 mb-12 xl:mb-0 px-2'>
                  <ComposedCharts
                    title='Revenues Amount'
                    subTitle='Top Weekly Revenues Amount'
                    height={600}
                    data={revenue}
                    allData={allData?.revenues}
                    bar='current_amount'
                    additional='Total Weekly Revenue'
                    color='teal'
                    loading={loading}
                  />
                </div>
                <div className='w-full lg:w-12/12 mt-8 mb-12 xl:mb-0 px-2'>
                  <TableRevenues
                    title='Weekly Revenues'
                    subTitle='All Weekly Revenues'
                    height={500}
                  />
                </div>
              </div>
            )}

            {tab === 4 && (
              <div className='flex flex-wrap my-4'>
                {/* CHURNS */}
                <div className='w-full lg:w-12/12 mb-12 xl:mb-0 px-2'>
                  <ComposedCharts
                    title='Churns Amount'
                    subTitle='Top Weekly Churns Amount'
                    height={600}
                    data={churns}
                    allData={allData?.churns}
                    bar='current_count'
                    additional='Total Churns Count'
                    color='pink'
                    loading={loading}
                  />
                </div>
                <div className='w-full lg:w-12/12 mt-8 mb-12 xl:mb-0 px-2'>
                  <TableChurns
                    title='Weekly Churns'
                    subTitle='All Weekly Churns'
                    height={500}
                  />
                </div>

                {/* CUSTOMER SESSIONS */}
                <div className='w-full lg:w-12/12 mb-12 xl:mb-0 px-2'>
                  <ComposedCharts
                    title='Customer Sessions Count'
                    subTitle='Top Weekly Customer Sessions Count'
                    height={600}
                    data={customerSessions}
                    allData={allData?.customer_sessions}
                    bar='current_count'
                    additional='Total Customer Sessions Count'
                    color='pink'
                    loading={loading}
                  />
                </div>
                <div className='w-full lg:w-12/12 mt-8 mb-12 xl:mb-0 px-2'>
                  <TableCustomerSessions
                    title='Weekly Customer Sessions'
                    subTitle='All Weekly Customer Sessions'
                    height={500}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Admin>
  );
}
