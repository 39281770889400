import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useCookies } from "react-cookie";

const UserDropdown = () => {
  const { logout } = useAuth0();
  const [, , removeCookie] = useCookies();

  const handleLogout = () => {
    removeCookie("token", { path: "/" });
    logout();
  };

  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  return (
    <>
      <a
        className='text-blueGray-500 block'
        href='#menu'
        ref={btnDropdownRef}
        onClick={() =>
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover()
        }>
        <div className='items-center flex'>
          <span className='w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full'>
            <i className='fa fa-user text-black' />
          </span>
        </div>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48 dropdown-bar"
        }>
        <a
          href='#menu'
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={handleLogout}>
          Logout
        </a>
      </div>
    </>
  );
};

export default UserDropdown;
