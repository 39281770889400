import React from "react";

export default function Kyc({ customer }) {
  return (
    <div className='flex flex-wrap mt-5 border-b pb-4'>
      <div className='w-full lg:w-6/12 px-6'>
        <div className='bg-emerald-500 wrapper p-5 mb-4'>
          <i className='fa fa-id-card mr-3' />{" "}
          <span className='uppercase font-bold text-lg'>
            Personal Information
          </span>
          {/* <div className='my-4'>
            <label
              className='uppercase text-white text-xs font-bold mb-2'
              htmlFor='grid-password'>
              Citizenship :
            </label>
            <label className='ml-2'>{customer?.kyc?.citizenship || "-"}</label>
          </div> */}
          <div className='my-4'>
            <label
              className='uppercase text-white text-xs font-bold mb-2'
              htmlFor='grid-password'>
              Date Of Birth :
            </label>
            <label className='ml-2'>{customer?.kyc?.dateOfBirth || "-"}</label>
          </div>
          <div className='my-4'>
            <label
              className='uppercase text-white text-xs font-bold mb-2'
              htmlFor='grid-password'>
              Place Of Birth :
            </label>
            <label className='ml-2'>{customer?.kyc?.placeOfBirth || "-"}</label>
          </div>
          <div className='my-4'>
            <label
              className='uppercase text-white text-xs font-bold mb-2'
              htmlFor='grid-password'>
              City :
            </label>
            <label className='ml-2'>{customer?.kyc?.dwCity || "-"}</label>
          </div>
          <div className='my-4'>
            <label
              className='uppercase text-white text-xs font-bold mb-2'
              htmlFor='grid-password'>
              Country :
            </label>
            <label className='ml-2'>{customer?.kyc?.dwCountry || "-"}</label>
          </div>
          <div className='my-4'>
            <label
              className='uppercase text-white text-xs font-bold mb-2'
              htmlFor='grid-password'>
              Province :
            </label>
            <label className='ml-2'>{customer?.kyc?.dwProvince || "-"}</label>
          </div>
          <div className='my-4'>
            <label
              className='uppercase text-white text-xs font-bold mb-2'
              htmlFor='grid-password'>
              Gender :
            </label>
            <label className='ml-2'>{customer?.kyc?.gender || "-"}</label>
          </div>
          <div className='my-4'>
            <label
              className='uppercase text-white text-xs font-bold mb-2'
              htmlFor='grid-password'>
              Home Ownership Status :
            </label>
            <label className='ml-2'>
              {customer?.kyc?.homeOwnershipStatus || "-"}
            </label>
          </div>
          <div className='my-4'>
            <label
              className='uppercase text-white text-xs font-bold mb-2'
              htmlFor='grid-password'>
              Marital Status :
            </label>
            <label className='ml-2'>
              {customer?.kyc?.maritalStatus || "-"}
            </label>
          </div>
        </div>
      </div>

      <div className='w-full lg:w-6/12 px-6'>
        <div className='bg-purple-500 wrapper p-5 mb-4'>
          <i className='fa fa-money-check mr-3' />{" "}
          <span className='uppercase font-bold text-lg'>Source of Funds</span>
          {/* <div className='my-2'>
            <label
              className='uppercase text-white text-xs font-bold mb-2'
              htmlFor='grid-password'>
              Annual Income :
            </label>
            <label className='ml-2'>{customer?.kyc?.annualIncome || "-"}</label>
          </div> 
          <div className='my-2'>
            <label
              className='uppercase text-white text-xs font-bold mb-2'
              htmlFor='grid-password'>
              Additonal Annual Income :
            </label>
            <label className='ml-2'>
              {customer?.kyc?.additionalAnnualIncome || "-"}
            </label>
          </div>
          */}
          <div className='my-2'>
            <label
              className='uppercase text-white text-xs font-bold mb-2'
              htmlFor='grid-password'>
              Source Of Wealth :
            </label>
            <label className='ml-2'>
              {customer?.kyc?.sourceOfWealth || "-"}
            </label>
          </div>
        </div>

        <div className='bg-purple-500 wrapper p-5 mb-4'>
          <i className='fa fa-money-check mr-3' />{" "}
          <span className='uppercase font-bold text-lg'>
            Investment Profile
          </span>
          <div className='my-2'>
            <label
              className='uppercase text-white text-xs font-bold mb-2'
              htmlFor='grid-password'>
              Investment Experience :
            </label>
            <label className='ml-2'>
              {customer?.kyc?.investmentExperience || "-"}
            </label>
          </div>
          <div className='my-2'>
            <label
              className='uppercase text-white text-xs font-bold mb-2'
              htmlFor='grid-password'>
              Investment Objective :
            </label>
            <label className='ml-2'>
              {customer?.kyc?.investmentObjective || "-"}
            </label>
          </div>
        </div>

        <div className='bg-indigo-500 wrapper p-5 mb-4'>
          <i className='fa fa-exclamation-triangle mr-3' />{" "}
          <span className='uppercase font-bold text-lg'>Risk Assessment</span>
          <div className='my-2'>
            <label
              className='uppercase text-white text-xs font-bold mb-2'
              htmlFor='grid-password'>
              Risk Level :
            </label>
            <label className='ml-2'>{customer?.kyc?.riskLevel || "-"}</label>
          </div>
          <div className='my-2'>
            <label
              className='uppercase text-white text-xs font-bold mb-2'
              htmlFor='grid-password'>
              Risk Score :
            </label>
            <label className='ml-2'>{customer?.kyc?.riskScore || "-"}</label>
          </div>
        </div>
      </div>
    </div>
  );
}
