import React from "react";
import Skeleton from "react-loading-skeleton";

export default function MainProfile({ customer, loading }) {
  return (
    <div className='flex flex-wrap'>
      <div className='w-full lg:w-12/12 xl:mb-0 px-6'>
        <div className='my-4 flex'>
          {customer?.profile?.email && (
            <label className='ml-2'>
              {loading ? (
                <Skeleton height={28} width={150} />
              ) : (
                <span className='icon-wrapper text-white bg-lightBlue-800'>
                  <i className='fa fa-envelope' /> {customer?.profile?.email}
                </span>
              )}
            </label>
          )}

          <label className='ml-2'>
            {loading ? (
              <Skeleton height={28} width={150} />
            ) : (
              <span className='icon-wrapper text-white bg-lightBlue-800'>
                <i className='fa fa-phone' /> {customer?.profile?.phoneNumber}
              </span>
            )}
          </label>

          <label className='ml-2'>
            {loading ? (
              <Skeleton height={28} width={250} />
            ) : (
              <span className='icon-wrapper text-white bg-indigo-500'>
                <label
                  className='uppercase text-white text-sm font-bold mb-2 mr-2'
                  htmlFor='grid-password'>
                  Go Trade Account Number :
                </label>
                {customer?.profile?.gotradeAccountNumber || "-"}
              </span>
            )}
          </label>
        </div>
      </div>
    </div>
  );
}
