import React from "react";
import styles from "./styles/Modal.module.sass";

const Modal = ({ size, detail, setDetail, setContent, children }) => {
  return (
    <div className={detail ? styles.modalOpen : styles.modalClose}>
      <div className={size === "lg" ? styles.wrapperLg : styles.wrapperMd}>
        <span
          onClick={() => {
            setDetail("");
            setContent("");
          }}
          className={styles.close}>
          <i className='fa fa-times' />
        </span>
        {children}
      </div>
    </div>
  );
};

export default Modal;
