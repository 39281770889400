import axios from "axios";

const getCookieValue = (name) =>
  document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

export const logout = () => {
  let expDate = new Date();
  expDate.setTime(expDate.getTime() - 1);
  document.cookie = `token=; path=/; expires=${expDate.toUTCString()}`;
  window.location.href = "/";
};

export async function sendApiRequest(method, url, payload, isImage) {
  const cookies = getCookieValue("token");
  const token = cookies;

  const settings = {
    method: method,
    url: process.env.REACT_APP_API_URL + url,
  };

  if (payload && !isImage) settings.data = JSON.stringify(payload);

  if (payload && isImage) {
    const formData = new FormData();

    Object.keys(payload).forEach((key) => {
      if (key === "images") {
        if (payload[key]?.length > 0) {
          for (var i = 0; i < payload[key].length; i++) {
            formData.append(key, payload[key][i]);
          }
        }
      } else {
        formData.append(key, payload[key]);
      }
    });
    settings.data = formData;
  }

  if (token && !isImage) {
    settings.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  } else if (token && isImage) {
    settings.headers = {
      Accept: "application/www-x-form-urlencoded",
      "Content-Type": "application/www-x-form-urlencoded",
      Authorization: `Bearer ${token}`,
    };
  } else {
    settings.headers = {
      "Content-Type": "application/json",
    };
  }

  try {
    const res = await axios(settings);
    return res;
  } catch (err) {
    if (err?.response?.data) {
      console.error("error when calling apis:", err?.response?.data);
      if (err?.response?.data?.statusCode === 401) {
        logout();
      }
      return err?.response?.data;
    } else {
      console.error("error when calling api:", err?.response);
      if (err?.response?.status?.statusCode === 401) {
        logout();
      } else {
        return err?.response;
      }
    }
    throw err;
  }
}
