import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sendGetRequest } from "api/helper";

export const getProfile = createAsyncThunk("auth/getProfile", async () => {
  const response = await sendGetRequest("/api/v1/users/me");
  return response;
});

export const getPermissions = createAsyncThunk(
  "auth/getPermissions",
  async () => {
    const response = await sendGetRequest("/api/v1/permissions");
    return response;
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    profile: null,
    permissions: null,
  },
  reducers: {},
  extraReducers: {
    [getProfile.pending]: (state) => {
      state.loading = true;
    },
    [getProfile.fulfilled]: (state, { payload }) => {
      state.profile = payload;
      state.loading = false;
    },
    [getProfile.rejected]: (state) => {
      state.loading = false;
    },

    [getPermissions.pending]: (state) => {
      state.loading = true;
    },
    [getPermissions.fulfilled]: (state, { payload }) => {
      state.permissions = payload;
      state.loading = false;
    },
    [getPermissions.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default authSlice.reducer;
export const selectProfile = (state) => state.auth;
