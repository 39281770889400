import React, { useEffect, useState } from "react";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  // Legend,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import { PropagateLoader } from "react-spinners";

export default function ComposedCharts({
  title,
  subTitle,
  data,
  allData,
  height,
  additional,
  color,
  bar,
  line,
  area,
  loading,
}) {
  let dollarUSLocale = Intl.NumberFormat("en-US");

  const [total, setTotal] = useState(0);

  useEffect(() => {
    let sum = 0;
    if (allData?.length > 0) {
      allData?.forEach((item) => {
        sum += Number(item[bar]);
      });
    }
    setTotal(sum);
  }, [allData, bar]);

  return (
    <div className='relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded'>
      <div className='rounded-t mb-0 px-4 py-3 bg-transparent'>
        <div className='flex flex-wrap items-center'>
          <div className='relative w-full max-w-full flex-grow flex-1'>
            <h6 className='uppercase text-blueGray-400 mb-1 text-xs font-semibold'>
              {title}
            </h6>
            <h2 className='text-blueGray-700 text-xl font-semibold'>
              {subTitle}
            </h2>
            {data?.length > 0 && (
              <div className='uppercase font-bold text-sm text-blueGray-400'>
                {moment(data[0]?.start_date).format("YYYY-MM-DD")} ~{" "}
                {moment(data[0]?.end_date).format("YYYY-MM-DD")}
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        {/* Chart */}
        <div className={`relative h-${height}-px`}>
          {loading ? (
            <div className='flex items-center justify-center h-500-px'>
              <PropagateLoader color='#36d7b7' />
            </div>
          ) : (
            <>
              <ResponsiveContainer width='100%' height={`87%`}>
                <ComposedChart
                  layout='vertical'
                  width={500}
                  height={900}
                  data={data}
                  margin={{
                    top: 20,
                    right: 80,
                    bottom: 20,
                    left: 50,
                  }}>
                  <CartesianGrid stroke='#f5f5f5' />
                  <YAxis
                    type='category'
                    angle={0}
                    textAnchor='end'
                    dataKey='name'
                    style={{ fontSize: 12 }}
                  />
                  <XAxis
                    type='number'
                    tickFormatter={(value) => [
                      bar === "current_count"
                        ? value
                        : "$" + dollarUSLocale.format(value),
                    ]}
                  />
                  <Tooltip
                    itemStyle={{ textTransform: "capitalize" }}
                    formatter={(value, name) => [
                      bar === "current_count"
                        ? value
                        : "$" + dollarUSLocale.format(value),
                      name?.replaceAll("_", " "),
                    ]}
                  />
                  {/* <Legend
                wrapperStyle={{ textTransform: "capitalize" }}
                formatter={(name) => [name?.replaceAll("_", " ")]}
              /> */}
                  {area && (
                    <Area
                      type='monotone'
                      dataKey={area}
                      fill='#8884d8'
                      stroke='#8884d8'
                    />
                  )}
                  {bar && (
                    <Bar dataKey={bar} barSize={10} fill='#413ea0'>
                      {
                        // Mapping over data to conditionally color bars
                        Array.isArray(data) &&
                        data.map((entry, index) => {
                          const color = entry[bar] > 0 ? 'green' : 'red'; // Conditional coloring based on value
                          return <Cell key={`cell-${index}`} fill={color} />;
                        })
                      }
                    </Bar>
                  )}
                  {line && (
                    <Line type='monotone' dataKey={line} stroke='#ff7300' />
                  )}
                </ComposedChart>
              </ResponsiveContainer>

              {additional && (
                <div
                  className={`bg-${
                    color || `purple`
                  }-500 wrapper-additional p-5 text-center`}>
                  <div className='text-lg'>{additional}</div>
                  <div className='text-3xl'>
                    {bar === "current_amount" && "$"}
                    {dollarUSLocale.format(total)}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
