import React, { useState } from "react";
import CustomerDashboard from "./CustomerDashboard";
import AccountPerformance from "./AccountPerformance";
import moment from "moment";

export default function UsdSummary({ customer }) {
  let dollarUSLocale = Intl.NumberFormat("en-US");
  let percentageLocale = Intl.NumberFormat("en-US");
  const [tab, setTab] = useState(1);

  return (
    <>
      <div className='flex flex-wrap mt-5'>
        <div className='w-full lg:w-4/12 px-6'>
          <div className='bg-purple-500 wrapper p-5 mb-4'>
            <i className='fa fa-wallet mr-3' />{" "}
            <span className='uppercase font-bold'>Cash</span>
            <div className='mt-3'>
              <div className='my-2'>
                <label
                  className='block uppercase text-white text-xs font-bold mb-1'
                  htmlFor='grid-password'>
                  Cash Balance
                </label>
                <label className='text-xl'>
                  $
                  {dollarUSLocale.format(
                    Number(customer?.cashUsd?.cashBalance).toFixed(2)
                  ) || "-"}
                </label>
              </div>
              <div className='my-2'>
                <label
                  className='block uppercase text-white text-xs font-bold mb-1'
                  htmlFor='grid-password'>
                  Cash Available For Trade
                </label>
                <label className='text-xl'>
                  $
                  {dollarUSLocale.format(
                    Number(customer?.cashUsd?.cashAvailableForTrade).toFixed(2)
                  ) || "-"}
                </label>
              </div>
              <div className='my-2'>
                <label
                  className='block uppercase text-white text-xs font-bold mb-1'
                  htmlFor='grid-password'>
                  Cash Available For Withdrawal
                </label>
                <label className='text-xl'>
                  $
                  {dollarUSLocale.format(
                    Number(
                      customer?.cashUsd?.cashAvailableForWithdrawal
                    ).toFixed(0)
                  ) || "-"}
                </label>
              </div>
            </div>
          </div>

          <div className='bg-purple-500 wrapper p-5 mb-4'>
            <div>
              <i className='fa fa-undo mr-3' />{" "}
              <span className='uppercase font-bold'>Return</span>
              <div className='mt-2 mb-3'>
                <label
                  className='block uppercase text-white text-xs font-bold mb-1'
                  htmlFor='grid-password'>
                  Return Amount
                </label>
                <label className='text-xl'>
                  $
                  {dollarUSLocale.format(
                    Number(customer?.returnUsd?.totalReturn).toFixed(2)
                  ) || "-"}
                </label>
              </div>
              <div className='mb-1'>
                <label
                  className='block uppercase text-white text-xs font-bold mb-1'
                  htmlFor='grid-password'>
                  Return Percentage
                </label>
                <label className='text-xl'>
                  {percentageLocale.format(
                    Number(customer?.returnUsd?.totalReturnPercentage).toFixed(
                      2
                    )
                  ) || "-"}
                  %
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className='w-full lg:w-8/12 px-6'>
          <div className='bg-indigo-500 wrapper p-5'>
            <i className='fa fa-money-check mr-3' />{" "}
            <span className='uppercase font-bold text-lg'>
              Funding Activity
            </span>
            <div className='mt-6'>
              <label
                className='block uppercase text-md font-bold mb-1 text-white'
                htmlFor='grid-password'>
                Deposit
              </label>
              <div className='mb-3'>
                <label
                  className='uppercase text-white text-xs font-bold mb-2'
                  htmlFor='grid-password'>
                  Total Amount :
                </label>
                <label className='ml-2'>
                  $
                  {dollarUSLocale.format(
                    Number(customer?.depositsUsd?.totalAmount).toFixed(2)
                  ) || "-"}
                </label>
              </div>

              <div className='uppercase text-xs mb-2 font-bold'>
                Latest 3 Deposits
              </div>

              <div className='block w-full overflow-x-auto mb-8'>
                <table className='items-center w-full bg-transparent border-collapse'>
                  <thead>
                    <tr>
                      <th
                        className={
                          "px-3 align-middle border border-solid py-1 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        }>
                        ID
                      </th>
                      <th
                        className={
                          "px-3 align-middle border border-solid py-1 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        }>
                        Date
                      </th>
                      <th
                        className={
                          "px-3 align-middle border border-solid py-1 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        }>
                        Amount
                      </th>
                      <th
                        className={
                          "px-3 align-middle border border-solid py-1 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        }>
                        Status
                      </th>
                      <th
                        className={
                          "px-3 align-middle border border-solid py-1 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        }>
                        Summary
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {customer?.depositsUsd?.latestDepositsHistory?.length >
                    0 ? (
                      customer?.depositsUsd?.latestDepositsHistory?.map(
                        (data, index) => (
                          <tr key={index}>
                            <td className='border-t-0 px-3 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-1'>
                              {data?.depositId}
                            </td>
                            <td className='border-t-0 px-3 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-1'>
                              {moment(data?.date).format("YYYY/MM/DD")}
                            </td>
                            <td className='border-t-0 px-3 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-1'>
                              ${data?.amount}
                            </td>
                            <td className='border-t-0 px-3 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-1'>
                              {data?.friendlyStatus}
                            </td>
                            <td className='border-t-0 px-3 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-1'>
                              {data?.summary}
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td
                          colSpan={5}
                          className='py-1 text-center uppercase text-xs'>
                          No Data Available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className='mt-6'>
                <label
                  className='block uppercase text-md font-bold mb-1 text-white'
                  htmlFor='grid-password'>
                  Withdrawals
                </label>
                <div className='mb-3'>
                  <label
                    className='uppercase text-white text-xs font-bold mb-2'
                    htmlFor='grid-password'>
                    Total Amount :
                  </label>
                  <label className='ml-2'>
                    $
                    {dollarUSLocale.format(
                      Number(customer?.withdrawalsUsd?.totalAmount).toFixed(2)
                    ) || "-"}
                  </label>
                </div>

                <div className='uppercase text-xs mb-2 font-bold'>
                  Latest 3 Withdrawals
                </div>

                <div className='block w-full overflow-x-auto mb-8'>
                  <table className='items-center w-full bg-transparent border-collapse'>
                    <thead>
                      <tr>
                        <th
                          className={
                            "px-3 align-middle border border-solid py-1 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                          }>
                          ID
                        </th>
                        <th
                          className={
                            "px-3 align-middle border border-solid py-1 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                          }>
                          Date
                        </th>
                        <th
                          className={
                            "px-3 align-middle border border-solid py-1 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                          }>
                          Amount
                        </th>
                        <th
                          className={
                            "px-3 align-middle border border-solid py-1 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                          }>
                          Status
                        </th>
                        <th
                          className={
                            "px-3 align-middle border border-solid py-1 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                          }>
                          Summary
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {customer?.withdrawalsUsd?.latestWithdrawalsHistory
                        ?.length > 0 ? (
                        customer?.withdrawalsUsd?.latestWithdrawalsHistory?.map(
                          (data, index) => (
                            <tr key={index}>
                              <td className='border-t-0 px-3 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-1'>
                                {data?.withdrawalId}
                              </td>
                              <td className='border-t-0 px-3 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-1'>
                                {moment(data?.date).format("YYYY/MM/DD")}
                              </td>
                              <td className='border-t-0 px-3 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-1'>
                                ${data?.amount}
                              </td>
                              <td className='border-t-0 px-3 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-1'>
                                {data?.friendlyStatus}
                              </td>
                              <td className='border-t-0 px-3 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-1'>
                                {data?.summary}
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td
                            colSpan={5}
                            className='py-1 text-center uppercase text-xs'>
                            No Data Available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='flex flex-wrap mt-5 bg-emerald-500 wrapper mx-6 mb-8'>
        <div className='w-full lg:w-12/12 px-6 mt-3'>
          <i className='fa fa-list mr-3' />{" "}
          <span className='uppercase font-bold text-lg'>Portfolio</span>
        </div>
        <div className='w-full lg:w-4/12 px-6'>
          <div className='my-4'>
            <label
              className='block uppercase text-md font-bold mb-2 text-white'
              htmlFor='grid-password'>
              Equity
            </label>

            <div className='my-1'>
              <label
                className='uppercase text-white text-xs font-bold mb-2'
                htmlFor='grid-password'>
                Total Value :
              </label>
              <label className='ml-2'>
                $
                {dollarUSLocale.format(
                  Number(customer?.equityUsd?.totalValue).toFixed(2)
                ) || "-"}
              </label>
            </div>
          </div>
        </div>

        <div className='w-full lg:w-4/12 px-6'>
          <div className='my-4'>
            <label
              className='block uppercase text-md font-bold mb-2 text-white'
              htmlFor='grid-password'>
              Stocks
            </label>

            <div className='my-1'>
              <label
                className='uppercase text-white text-xs font-bold mb-2'
                htmlFor='grid-password'>
                Total Value :
              </label>
              <label className='ml-2'>
                $
                {dollarUSLocale.format(
                  Number(customer?.equityUsd?.stocks?.totalValue).toFixed(2)
                ) || "-"}
              </label>
            </div>

            <div className='my-1'>
              <label
                className='uppercase text-white text-xs font-bold mb-2'
                htmlFor='grid-password'>
                Percentage Of Stocks :
              </label>
              <label className='ml-2'>
                {dollarUSLocale.format(
                  customer?.equityUsd?.stocks?.percentageOfEquity
                ) || "-"}
                %
              </label>
            </div>
          </div>
        </div>

        <div className='w-full lg:w-4/12 px-6'>
          <div className='my-4'>
            <label
              className='block uppercase text-md font-bold mb-2 text-white'
              htmlFor='grid-password'>
              Cash
            </label>

            <div className='my-1'>
              <label
                className='uppercase text-white text-xs font-bold mb-2'
                htmlFor='grid-password'>
                Cash Balance :
              </label>
              <label className='ml-2'>
                $
                {dollarUSLocale.format(
                  customer?.equityUsd?.cash?.cashBalance
                ) || "-"}
              </label>
            </div>

            <div className='my-1'>
              <label
                className='uppercase text-white text-xs font-bold mb-2'
                htmlFor='grid-password'>
                Percentage Of Cash :
              </label>
              <label className='ml-2'>
                {dollarUSLocale.format(
                  customer?.equityUsd?.cash?.percentageOfEquity
                ) || "-"}
                %
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className='my-4'>
        <AccountPerformance customer={customer} />
      </div>

      <div className='flex flex-wrap mt-1 border-b pb-4'>
        <div className='w-full lg:w-12/12 px-0'>
          <div className='flex px-4 bg-blueGray-100 pt-6'>
            <div
              className={`tabs ${tab === 1 && "active"}`}
              onClick={() => setTab(1)}>
              Breakdown Table
            </div>

            <div
              className={`tabs ${tab === 2 && "active"}`}
              onClick={() => setTab(2)}>
              Charts
            </div>
          </div>

          {tab === 2 && <CustomerDashboard customer={customer} />}

          {tab === 1 && (
            <div className='block w-full overflow-x-auto mb-8'>
              <table className='items-center w-full bg-transparent border-collapse'>
                <thead>
                  <tr>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      }>
                      Name / Ticker
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      }>
                      Market Price
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      }>
                      Market Value
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      }>
                      Average Price
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      }>
                      Cost Basis
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      }>
                      Percentage
                      <br />
                      Of Equity
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      }>
                      Quantity
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      }>
                      Unrealized
                      <br />
                      PnL
                    </th>
                    <th
                      className={
                        "px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      }>
                      Day
                      <br />
                      Percent %
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-3'>
                      <strong>Cash / CASH</strong>
                    </td>
                    <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-3'>
                      -
                    </td>
                    <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-3'>
                      $
                      {dollarUSLocale.format(
                        customer?.equityUsd?.cash?.cashBalance
                      )}
                    </td>
                    <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-3'>
                      -
                    </td>
                    <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-3'>
                      -
                    </td>
                    <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-3'>
                      {customer?.equityUsd?.cash?.percentageOfEquity?.toFixed(
                        2
                      )}
                      %
                    </td>
                    <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-3'>
                      -
                    </td>
                    <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-3'>
                      -
                    </td>
                    <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-3'>
                      -
                    </td>
                  </tr>
                  {customer?.equityUsd?.stocks?.breakdown?.length > 0
                    ? customer?.equityUsd?.stocks?.breakdown?.map(
                        (data, index) => (
                          <tr key={index}>
                            <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-3'>
                              <strong>
                                {data?.name} / {data?.ticker}
                              </strong>
                            </td>
                            <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-3'>
                              ${dollarUSLocale.format(data?.marketPrice)}
                            </td>
                            <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-3'>
                              $
                              {dollarUSLocale.format(
                                Number(data?.marketValue)?.toFixed(2)
                              )}
                            </td>
                            <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-3'>
                              $
                              {dollarUSLocale.format(
                                Number(data?.averagePrice)?.toFixed(2)
                              )}
                            </td>
                            <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-3'>
                              $
                              {dollarUSLocale.format(
                                Number(data?.costBasis)?.toFixed(2)
                              )}
                            </td>
                            <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-3'>
                              {Number(data?.percentageOfEquity)?.toFixed(2)}%
                            </td>
                            <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-3'>
                              {Number(data?.quantity)?.toFixed(2)}
                            </td>
                            <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-3'>
                              {Number(data?.unrealizedPL) > 0 ? (
                                <span className='font-bold text-green'>
                                  {Number(data?.unrealizedPL)?.toFixed(2)}%
                                </span>
                              ) : (
                                <span className='font-bold text-red-500'>
                                  {Number(data?.unrealizedPL)?.toFixed(2)}%
                                </span>
                              )}
                            </td>
                            <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-3'>
                              {Number(data?.unrealizedDayPLPercent) > 0 ? (
                                <span className='font-bold text-green'>
                                  {Number(
                                    data?.unrealizedDayPLPercent
                                  )?.toFixed(2)}
                                  %
                                </span>
                              ) : (
                                <span className='font-bold text-red-500'>
                                  {Number(
                                    data?.unrealizedDayPLPercent
                                  )?.toFixed(2)}
                                  %
                                </span>
                              )}
                            </td>
                          </tr>
                        )
                      )
                    : null}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
