import React, { useEffect, useState } from "react";

// components
import GaugeCharts from "./Charts/GaugeCharts";
import CardPageVisits from "components/Cards/CardPageVisits.js";
import CardSocialTraffic from "components/Cards/CardSocialTraffic.js";
import Admin from "layouts/Admin";
import { sendGetRequest } from "api/helper";
import ComposedCharts from "./Charts/ComposedCharts";

export default function Dashboard() {
  const [netDeposits, setNetDeposits] = useState("");
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    async function getData() {
      const result = await sendGetRequest(
        `/api/v1/rms/me/metrics/weekly_output_metrics`
      );
      setData(result);

      const netDeposits = await sendGetRequest(
        `/api/v1/rms/me/metrics/latest_rms_weekly_net_deposits?limit=${15}&page=${1}&orderBy=-weekly_net_deposit_value_usd`
      );
      setNetDeposits(netDeposits?.data);

      setLoading(false);
    }
    getData();
  }, []);

  return (
    <Admin title='Dashboard'>
      <div className='flex flex-wrap'>
        <div className='w-full md:w-5/12 mb-12 xl:mb-0 px-2'>
          <GaugeCharts
            title='Net Deposits'
            subTitle='Weekly Net Deposits Amount'
            height={625}
            gaugeHeight={450}
            paddingTop={125}
            data={data?.net_deposit}
            additional={"Number of Customers"}
            color={"teal"}
            loading={loading}
          />
        </div>

        <div className='w-full md:w-7/12 mb-12 xl:mb-0 px-2'>
          {netDeposits?.length > 0 && (
            <ComposedCharts
              title='Net Deposit Amount'
              subTitle='Top Weekly Net Deposit Amount'
              height={600}
              data={netDeposits}
              allData={netDeposits}
              bar='current_amount'
              additional='Total Net Deposit Amount'
              color='teal'
              loading={loading}
            />
          )}
        </div>
      </div>

      <div className='flex flex-wrap mt-4'>
        <div className='w-full lg:w-6/12 mb-12 xl:mb-0 px-2'>
          <GaugeCharts
            title='Deposits'
            subTitle='Weekly Deposits Amount'
            height={100}
            data={data?.deposit}
            additional={"Weekly Deposit Count"}
            color={"indigo"}
            loading={loading}
          />
        </div>
        <div className='w-full lg:w-6/12 mb-12 xl:mb-0 px-2'>
          <GaugeCharts
            title='Withdrawals'
            subTitle='Weekly Withdrawals Amount'
            height={100}
            data={data?.withdrawal}
            additional={"Weekly Withdrawal Count"}
            color={"indigo"}
            loading={loading}
          />
        </div>
        {/* <div className='w-full md:w-4/12 mb-12 xl:mb-0 px-2'>
          <GaugeCharts
            title='Revenue'
            subTitle='Weekly Revenue'
            height={100}
            data={data.revenue}
            additional={"Number of Customer"}
            color={"teal"}
          />
        </div> */}
      </div>

      <div className='flex flex-wrap mt-4'>
        <div className='w-full lg:w-6/12 mb-12 xl:mb-0 px-2'>
          <GaugeCharts
            title='Buy Trade'
            subTitle='Weekly Buy Trade Amount'
            height={400}
            data={data?.buy_trade}
            additional={"Weekly Buy Trade Count"}
            color={"purple"}
            loading={loading}
          />
        </div>
        <div className='w-full lg:w-6/12 px-2'>
          <GaugeCharts
            title='Sell Trade'
            subTitle='Weekly Sell Trade Amount'
            height={400}
            data={data?.sell_trade}
            additional={"Weekly Sell Trade Count"}
            color={"purple"}
            loading={loading}
          />
        </div>
      </div>

      <div className='flex flex-wrap mt-4' style={{ display: "none" }}>
        <div className='w-full xl:w-8/12 mb-12 xl:mb-0 px-4'>
          <CardPageVisits />
        </div>
        <div className='w-full xl:w-4/12 px-4'>
          <CardSocialTraffic />
        </div>
      </div>
    </Admin>
  );
}
