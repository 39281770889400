import React, { useEffect, useState } from "react";
import moment from "moment";
import LoadingTable from "../LoadingTable";
import ReactPaginate from "react-paginate";
import { sendGetRequest } from "api/helper";

export default function Table({ title, subTitle, height }) {
  let dollarUSLocale = Intl.NumberFormat("en-US");

  const [page, setPage] = useState(1);
  const [data, setData] = useState("");
  const [sortBy, setSortBy] = useState("-portfolio_value_usd_end_of_week");
  const [loading, setLoading] = useState(false);
  const limit = 10;

  useEffect(() => {
    setLoading(true);
    async function getData() {
      const data = await sendGetRequest(
        `/api/v1/managers/me/rms_metrics/latest_weekly_portfolio?limit=${limit}&page=${page}&orderBy=${sortBy}`
      );
      setData(data);
      setLoading(false);
    }
    getData();
  }, [page, sortBy]);

  const onPageChange = (e) => {
    setPage(parseInt(e.selected + 1));
  };

  return (
    <div className='relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded'>
      <div className='rounded-t mb-0 px-4 py-3 bg-transparent'>
        <div className='flex flex-wrap items-center'>
          <div className='relative w-full max-w-full flex-grow flex-1'>
            <h6 className='uppercase text-blueGray-400 mb-1 text-xs font-semibold'>
              {title}
            </h6>
            <h2 className='text-blueGray-700 text-xl font-semibold'>
              {subTitle}
            </h2>
            {data?.length > 0 && (
              <div className='uppercase font-bold text-sm text-blueGray-400'>
                {moment(data[0]?.start_date).format("YYYY-MM-DD")} ~{" "}
                {moment(data[0]?.end_date).format("YYYY-MM-DD")}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={`relative h-${height}-px`}>
        <div className='block w-full overflow-x-auto'>
          <table className='items-center w-full bg-transparent border-collapse'>
            <thead>
              <tr>
                <th
                  className={
                    "px-3 align-middle border border-solid py-2 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  }>
                  Name
                </th>
                <th
                  className={
                    "px-3 align-middle border border-solid py-2 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  }>
                  Current Amount
                  {sortBy === "-portfolio_value_usd_end_of_week" ? (
                    <i
                      className='ml-3 fa fa-chevron-down cursor-pointer'
                      onClick={() =>
                        setSortBy("portfolio_value_usd_end_of_week")
                      }
                    />
                  ) : sortBy === "portfolio_value_usd_end_of_week" ? (
                    <i
                      className='ml-3 fa fa-chevron-up cursor-pointer'
                      onClick={() =>
                        setSortBy("-portfolio_value_usd_end_of_week")
                      }
                    />
                  ) : (
                    <i
                      className='ml-3 fa fa-chevron-up cursor-pointer opacity-50'
                      onClick={() =>
                        setSortBy("-portfolio_value_usd_end_of_week")
                      }
                    />
                  )}
                </th>
                <th
                  className={
                    "px-3 align-middle border border-solid py-2 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  }>
                  Previous Amount
                  {sortBy === "-prev_portfolio_value_usd_end_of_week" ? (
                    <i
                      className='ml-3 fa fa-chevron-down cursor-pointer'
                      onClick={() =>
                        setSortBy("prev_portfolio_value_usd_end_of_week")
                      }
                    />
                  ) : sortBy === "prev_portfolio_value_usd_end_of_week" ? (
                    <i
                      className='ml-3 fa fa-chevron-up cursor-pointer'
                      onClick={() =>
                        setSortBy("-prev_portfolio_value_usd_end_of_week")
                      }
                    />
                  ) : (
                    <i
                      className='ml-3 fa fa-chevron-up cursor-pointer opacity-50'
                      onClick={() =>
                        setSortBy("-prev_portfolio_value_usd_end_of_week")
                      }
                    />
                  )}
                </th>
                <th
                  className={
                    "px-3 align-middle border border-solid py-2 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  }>
                  Amount Change Percentage
                  {sortBy === "-perc_portfolio_value_usd_end_of_week_change" ? (
                    <i
                      className='ml-3 fa fa-chevron-down cursor-pointer'
                      onClick={() =>
                        setSortBy("perc_portfolio_value_usd_end_of_week_change")
                      }
                    />
                  ) : sortBy ===
                    "perc_portfolio_value_usd_end_of_week_change" ? (
                    <i
                      className='ml-3 fa fa-chevron-up cursor-pointer'
                      onClick={() =>
                        setSortBy(
                          "-perc_portfolio_value_usd_end_of_week_change"
                        )
                      }
                    />
                  ) : (
                    <i
                      className='ml-3 fa fa-chevron-up cursor-pointer opacity-50'
                      onClick={() =>
                        setSortBy(
                          "-perc_portfolio_value_usd_end_of_week_change"
                        )
                      }
                    />
                  )}
                </th>
                <th
                  className={
                    "px-3 align-middle border border-solid py-2 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  }>
                  Number of Customers
                </th>
              </tr>
            </thead>
            {loading ? (
              <LoadingTable row={7} column={5} />
            ) : (
              <tbody>
                {data?.data?.length > 0
                  ? data?.data?.map((data, index) => (
                      <tr key={index}>
                        <td className='border-t-0 px-3 align-middle border-l-0 border-r-0 border-b text-xs font-bold whitespace-nowrap p-3'>
                          {data?.name}
                        </td>
                        <td className='border-t-0 px-3 align-middle border-l-0 border-r-0 border-b text-xs whitespace-nowrap p-3'>
                          $
                          {dollarUSLocale.format(
                            Number(data.current_amount)?.toFixed(2)
                          )}
                        </td>
                        <td className='border-t-0 px-3 align-middle border-l-0 border-r-0 border-b text-xs whitespace-nowrap p-3'>
                          $
                          {dollarUSLocale.format(
                            Number(data.previous_amount)?.toFixed(2)
                          )}
                        </td>
                        <td className='border-t-0 px-3 align-middle border-l-0 border-r-0 border-b text-xs whitespace-nowrap p-3'>
                          {Number(data.amount_change_percentage) > 0 ? (
                            <span className='font-bold text-green'>
                              {Number(data.amount_change_percentage)?.toFixed(
                                2
                              )}
                              %
                            </span>
                          ) : (
                            <span className='font-bold text-red-500 '>
                              {Number(data.amount_change_percentage)?.toFixed(
                                2
                              )}
                              %
                            </span>
                          )}
                        </td>
                        <td className='border-t-0 px-3 align-middle border-l-0 border-r-0 border-b text-xs whitespace-nowrap p-3'>
                          {dollarUSLocale.format(data.number_of_customers)}
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            )}
          </table>

          <ReactPaginate
            previousLabel={"<"}
            disabledClassName={"disabled"}
            nextLabel={">"}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            marginPagesDisplayed={5}
            pageRangeDisplayed={1}
            pageCount={Math.ceil(data?.totalRecords / limit)}
            onPageChange={onPageChange}
            // forcePage={page}
          />
        </div>
      </div>
    </div>
  );
}
