import Skeleton from "react-loading-skeleton";

const LoadingTable = ({ row, column }) => {
  return (
    <tbody>
      {Array.apply(null, Array(row)).map((data, index) => (
        <tr key={index}>
          {Array.apply(null, Array(column)).map((data, idx) => (
            <td
              key={idx}
              className='border-t-0 px-6 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-3'
              width={`${100 / column}%`}>
              <div style={{ width: `${100}%` }}>
                <Skeleton height={25} />
              </div>
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default LoadingTable;
