import React from "react";
import styles from "./styles/Login.module.scss";
import BigLogo from "../../assets/img/logo-icon.png";
import { useAuth0 } from "@auth0/auth0-react";

export default function Login() {
  const { loginWithRedirect } = useAuth0();

  return (
    <main>
      <section className='relative w-full h-full py-40 min-h-screen'>
        <div
          className={`${styles.cover} absolute top-0 w-full h-full bg-no-repeat bg-full`}
        />
        <div
          className={`${styles.wrapper} absolute top-0 w-full h-full bg-no-repeat bg-full`}
        />
        <img
          src={BigLogo}
          alt=''
          className={`${styles.bigLogo} absolute top-0`}
        />

        <div className='container mx-auto px-4 h-full'>
          <div className='flex content-center items-center justify-center h-full'>
            <div className='w-full lg:w-4/12 px-4'>
              <div className='relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0'>
                <div className='rounded-t mb-0 px-6 py-6'>
                  <div className='text-center mb-3'>
                    <h1 className='text-teal-500 text-3xl font-bold mt-4 mb-6'>
                      Welcome to GOPULSE
                    </h1>

                    <h2 className='text-blueGray-700 text-lg font-bold mb-5'>
                      Gotrade Personalized User Lifecycle & Service Engagement
                    </h2>
                    <h6 className='text-blueGray-500 text-sm font-bold'>
                      Login/Sign In
                    </h6>
                  </div>
                  <div className='btn-wrapper text-center'>
                    <button
                      onClick={loginWithRedirect}
                      className='bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-sm ease-linear transition-all duration-150'
                      type='button'>
                      <img
                        alt='...'
                        className='w-5 mr-2'
                        src={require("assets/img/google.svg").default}
                      />
                      Continue with Google
                    </button>
                  </div>
                  <hr className='mt-6 border-b-1 border-blueGray-300' />
                </div>

                <div className='flex-auto px-4 lg:px-10 py-10 pt-0' hidden>
                  <div className='text-blueGray-400 text-center mb-3 font-bold'>
                    <small>Or sign in with credentials</small>
                  </div>
                  <form>
                    <div className='relative w-full mb-3'>
                      <label
                        className='block uppercase text-blueGray-600 text-xs font-bold mb-2'
                        htmlFor='grid-password'>
                        Email
                      </label>
                      <input
                        type='email'
                        className='border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                        placeholder='Email'
                      />
                    </div>

                    <div className='relative w-full mb-3'>
                      <label
                        className='block uppercase text-blueGray-600 text-xs font-bold mb-2'
                        htmlFor='grid-password'>
                        Password
                      </label>
                      <input
                        type='password'
                        className='border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                        placeholder='Password'
                      />
                    </div>
                    <div>
                      <label className='inline-flex items-center cursor-pointer'>
                        <input
                          id='customCheckLogin'
                          type='checkbox'
                          className='form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150'
                        />
                        <span className='ml-2 text-sm font-semibold text-blueGray-600'>
                          Remember me
                        </span>
                      </label>
                    </div>

                    <div className='text-center mt-6'>
                      <button
                        className='bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150'
                        type='button'>
                        Sign In
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
