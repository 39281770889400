import Modal from "components/Utilities/Modal";
import Editors from "components/Utilities/Editor";
import React, { useEffect, useState } from "react";
import { sendGetRequest } from "api/helper";
import { sendPostRequest } from "api/helper";
// import ReactPaginate from "react-paginate";
import LoadingTable from "views/admin/LoadingTable";
import { sendPutRequest, sendDeleteRequest } from "api/helper";
import { useSelector } from "react-redux";
import { selectProfile } from "store/slice/auth";

export default function Notes() {
  const { profile } = useSelector(selectProfile);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const [detail, setDetail] = useState("");
  const [lists, setLists] = useState([]);

  const page = 1;
  const limit = 100;

  useEffect(() => {
    setLoading(true);
    async function getData() {
      const result = await sendGetRequest(
        `/api/v1/users/me/notes?page=${page}&limit=${limit}`
      );
      setLists(result);
      setLoading(false);
    }
    getData();
  }, []);

  const handleCreate = async (e) => {
    e.preventDefault();
    const postObj = {
      topic: detail?.topic,
      content: content,
      tag: detail?.tag,
      createdBy: profile?.id,
    };
    await sendPostRequest("/api/v1/users/me/notes", postObj);

    setLoading(true);
    const result = await sendGetRequest(
      `/api/v1/users/me/notes?page=${page}&limit=${limit}`
    );
    setLists(result);
    setLoading(false);
    setDetail("");
    setContent("");
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const postObj = {
      topic: detail?.topic,
      content: content,
      tag: detail?.tag,
      createdBy: detail?.createdBy,
      updatedBy: profile?.id,
    };
    await sendPutRequest(`/api/v1/users/me/notes/${detail?.id}`, postObj);

    setLoading(true);
    const result = await sendGetRequest(
      `/api/v1/users/me/notes?page=${page}&limit=${limit}`
    );
    setLists(result);
    setLoading(false);
    setDetail("");
    setContent("");
  };

  const handleEdit = async (data) => {
    setDetail(data);
    setContent(data?.content);
  };

  const handleDelete = async (id) => {
    await sendDeleteRequest(`/api/v1/users/me/notes/${id}`);

    setLoading(true);
    const result = await sendGetRequest(
      `/api/v1/users/me/notes?page=${page}&limit=${limit}`
    );
    setLists(result);
    setLoading(false);
    setDetail("");
    setContent("");
  };

  const addData = () => {
    setDetail({
      id: "new",
      topic: "",
      tag: "",
    });
  };

  return (
    <>
      <Modal
        size='lg'
        detail={detail}
        setDetail={setDetail}
        setContent={setContent}>
        <form onSubmit={detail?.id === "new" ? handleCreate : handleUpdate}>
          <h6 className='text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase'>
            {detail?.id === "new" ? "Add" : "Edit"} Notes
          </h6>

          <div className='flex flex-wrap'>
            <div className='w-full px-4'>
              <div className='relative w-full mb-3'>
                <label
                  className='block uppercase text-blueGray-600 text-xs font-bold mb-2'
                  htmlFor='grid-password'>
                  Topic
                </label>
                <input
                  required
                  type='text'
                  className='border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                  value={detail?.topic}
                  onChange={(e) =>
                    setDetail({ ...detail, topic: e.target.value })
                  }
                />
              </div>

              <div className='relative w-full mb-3'>
                <label
                  className='block uppercase text-blueGray-600 text-xs font-bold mb-2'
                  htmlFor='grid-password'>
                  Content
                </label>

                <Editors data={content} setData={setContent} />
              </div>

              {/* <div className='relative w-full mb-3'>
                <label
                  className='block uppercase text-blueGray-600 text-xs font-bold mb-2'
                  htmlFor='grid-password'>
                  Tag
                </label>
                <input
                  required
                  type='text'
                  className='border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                  value={detail?.tag}
                  onChange={(e) =>
                    setDetail({ ...detail, tag: e.target.value })
                  }
                />
              </div> */}
            </div>
          </div>

          <div className='w-full px-4'>
            <button
              className='bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150'
              type='submit'>
              Save
            </button>
          </div>
        </form>
      </Modal>

      <div className='flex flex-wrap mt-5'>
        {profile?.permissions?.find(
          (item) => item?.permission === "create:user_notes"
        ) && (
          <div className='w-full lg:w-12/12 px-6 mb-5'>
            <button
              className='bg-teal-500 text-white active:bg-teal-600 text-sm font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
              type='button'
              onClick={addData}>
              <i className='fa fa-plus mr-2' /> Add Notes
            </button>
          </div>
        )}

        <div className='w-full lg:w-12/12 px-6 mb-5'>
          <table className='items-center w-full bg-transparent border-collapse'>
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  }>
                  Topic
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  }>
                  Content
                </th>
                {/* <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  }>
                  Tag
                </th> */}
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                  }
                />
              </tr>
            </thead>
            {loading ? (
              <LoadingTable row={limit - 1} column={4} />
            ) : (
              <tbody>
                {lists?.length > 0 ? (
                  lists?.map((data, index) => (
                    <tr key={index}>
                      <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-3'>
                        <div className='max-w-120-px text-ellipsis'>
                          {data?.topic}
                        </div>
                      </td>
                      <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-3'>
                        <div
                          className='max-w-120-px text-ellipsis'
                          dangerouslySetInnerHTML={{
                            __html: data?.content,
                          }}
                        />
                      </td>
                      {/* <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-3'>
                        <div className='max-w-120-px text-ellipsis'>
                          {data?.tag}
                        </div>
                      </td> */}
                      <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-3 text-right'>
                        {profile?.permissions?.find(
                          (item) => item?.permission === "update:user_notes"
                        ) && (
                          <i
                            className='fa fa-edit text-lightBlue-500 pointer mr-3 text-lg'
                            onClick={() => handleEdit(data)}
                          />
                        )}
                        {profile?.permissions?.find(
                          (item) => item?.permission === "delete:user_notes"
                        ) && (
                          <i
                            className='fa fa-times text-red-500 pointer text-lg'
                            onClick={() => handleDelete(data?.id)}
                          />
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={4}
                      className='text-center border-t-0 px-6 align-middle border-l-0 border-r-0 border-b text-sm whitespace-nowrap p-3'>
                      No notes available
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>

          {/* <ReactPaginate
            previousLabel={"<"}
            disabledClassName={"disabled"}
            nextLabel={">"}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            marginPagesDisplayed={5}
            pageRangeDisplayed={1}
            pageCount={Math.ceil(lists?.totalRecords / limit)}
            // onPageChange={onPageChange}
            // forcePage={page}
          /> */}
        </div>
      </div>
    </>
  );
}
