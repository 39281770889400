import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import Platform from "./Platform";
// import { useEffect } from "react";

function App() {
  // useEffect(() => {
  //   document.addEventListener("contextmenu", (event) => event.preventDefault());
  // }, []);

  return (
    <div className='app'>
      <Provider store={store}>
        <BrowserRouter>
          <Platform />
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
