import React from "react";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

export default function Admin({ children, title }) {
  return (
    <>
      <Sidebar />
      <div className='relative md:ml-64 bg-blueGray-100 h-full-vh'>
        <AdminNavbar title={title} />
        {/* Header */}
        <HeaderStats />
        <div className='px-4 md:px-10 mx-auto w-full -m-24'>
          {children}
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
