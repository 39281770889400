import moment from "moment";
import React, { useCallback, useState } from "react";

import "react-big-calendar/lib/css/react-big-calendar.css";
// import "react-big-calendar/lib/sass/styles";
// import "react-big-calendar/lib/addons/dragAndDrop/styles";

import { Calendar, momentLocalizer } from "react-big-calendar";

const CalendarModule = ({ eventItems, calendarlist }) => {
  const localizer = momentLocalizer(moment);

  const now = new Date();
  const [events, setEvents] = useState([
    {
      id: 1,
      title: "Event 1",
      start: new Date(new Date().setHours(new Date().getHours() - 3)),
      end: new Date(new Date().setHours(new Date().getHours() + 3)),
    },
    {
      id: 2,
      title: "Event 2",
      start: now,
      end: now,
    },
    {
      id: 3,
      title: "Event 3",
      start: now,
      end: now,
    },
  ]);

  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      const title = window.prompt("New Event name");
      if (title) {
        setEvents((prev) => [...prev, { start, end, title }]);
      }
    },
    [setEvents]
  );

  const handleSelectEvent = useCallback(
    (event) => window.alert(event.title),
    []
  );

  // const { defaultDate, scrollToTime } = useMemo(
  //   () => ({
  //     defaultDate: new Date(2015, 3, 12),
  //     scrollToTime: new Date(1970, 1, 1, 6),
  //   }),
  //   []
  // );

  return (
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor='start'
        endAccessor='end'
        style={{ height: 650 }}
        popup
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        selectable
      />
    </div>
  );
};

export default CalendarModule;
