import React, { useEffect, useState } from "react";
import { ResponsiveContainer, LineChart, Line, Tooltip, YAxis } from "recharts";
import Background from "../../../assets/img/patterns.png";
import moment from "moment";

export default function AccountPerformance({ customer }) {
  const [option, setOption] = useState("All");
  const [performance, setPerformance] = useState([]);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);

  let dollarUSLocale = Intl.NumberFormat("en-US");

  useEffect(() => {
    if (customer) {
      setPerformance(customer?.accountPerformance?.performances);
    }
  }, [customer]);

  useEffect(() => {
    if (option === "1W") {
      let firstDate = moment(new Date()).format("YYYY-MM-DD");
      let secondDate = moment(firstDate, "YYYY-MM-DD").subtract("1", "week");

      const filter = customer?.accountPerformance?.performances?.filter(
        (item) => new Date(item.date) >= new Date(secondDate)
      );

      const lowest = filter.reduce((previous, current) => {
        return current.value < previous.value ? current : previous;
      });

      const highest = filter.reduce((previous, current) => {
        return current.value > previous.value ? current : previous;
      });

      setMinValue(lowest);
      setMaxValue(highest);
      setPerformance(filter);
    } else if (option === "1M") {
      let firstDate = moment(new Date()).format("YYYY-MM-DD");
      let secondDate = moment(firstDate, "YYYY-MM-DD").subtract("1", "months");

      const filter = customer?.accountPerformance?.performances?.filter(
        (item) => new Date(item.date) >= new Date(secondDate)
      );

      const lowest = filter.reduce((previous, current) => {
        return current.value < previous.value ? current : previous;
      });

      const highest = filter.reduce((previous, current) => {
        return current.value > previous.value ? current : previous;
      });

      setMinValue(lowest);
      setMaxValue(highest);
      setPerformance(filter);
    } else if (option === "3M") {
      let firstDate = moment(new Date()).format("YYYY-MM-DD");
      let secondDate = moment(firstDate, "YYYY-MM-DD").subtract("3", "months");

      const filter = customer?.accountPerformance?.performances?.filter(
        (item) => new Date(item.date) >= new Date(secondDate)
      );

      const lowest = filter.reduce((previous, current) => {
        return current.value < previous.value ? current : previous;
      });

      const highest = filter.reduce((previous, current) => {
        return current.value > previous.value ? current : previous;
      });

      setMinValue(lowest);
      setMaxValue(highest);
      setPerformance(filter);
    } else if (option === "6M") {
      let firstDate = moment(new Date()).format("YYYY-MM-DD");
      let secondDate = moment(firstDate, "YYYY-MM-DD").subtract("6", "months");

      const filter = customer?.accountPerformance?.performances?.filter(
        (item) => new Date(item.date) >= new Date(secondDate)
      );

      const lowest = filter.reduce((previous, current) => {
        return current.value < previous.value ? current : previous;
      });

      const highest = filter.reduce((previous, current) => {
        return current.value > previous.value ? current : previous;
      });

      setMinValue(lowest);
      setMaxValue(highest);
      setPerformance(filter);
    } else if (option === "YTD") {
      let secondDate = `01-01-${new Date().getFullYear()}`;
      const filter = customer?.accountPerformance?.performances?.filter(
        (item) => new Date(item.date) >= new Date(secondDate)
      );

      const lowest = filter.reduce((previous, current) => {
        return current.value < previous.value ? current : previous;
      });

      const highest = filter.reduce((previous, current) => {
        return current.value > previous.value ? current : previous;
      });

      setMinValue(lowest);
      setMaxValue(highest);
      setPerformance(filter);
    } else if (option === "1Y") {
      let firstDate = moment(new Date()).format("YYYY-MM-DD");
      let secondDate = moment(firstDate, "YYYY-MM-DD").subtract("1", "years");

      const filter = customer?.accountPerformance?.performances?.filter(
        (item) => new Date(item.date) >= new Date(secondDate)
      );

      const lowest = filter.reduce((previous, current) => {
        return current.value < previous.value ? current : previous;
      });

      const highest = filter.reduce((previous, current) => {
        return current.value > previous.value ? current : previous;
      });

      setMinValue(lowest);
      setMaxValue(highest);
      setPerformance(filter);
    } else {
      const lowest = customer?.accountPerformance?.performances?.reduce(
        (previous, current) => {
          return current.value < previous.value ? current : previous;
        }
      );

      const highest = customer?.accountPerformance?.performances?.reduce(
        (previous, current) => {
          return current.value > previous.value ? current : previous;
        }
      );

      setMinValue(lowest);
      setMaxValue(highest);
      setPerformance(customer?.accountPerformance?.performances);
    }
  }, [customer?.accountPerformance?.performances, option]);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload?.length) {
      return (
        <div className='custom-tooltip'>
          <p className='label uppercase font-bold text-xs'>{`Date : ${payload[0].payload.date}`}</p>
          <p className='label uppercase font-bold text-xs'>{`Value : $${
            dollarUSLocale.format(payload[0]?.value) || "-"
          }`}</p>
        </div>
      );
    }
  };

  return (
    <div className='graphs-container'>
      <img src={Background} alt='' className='img-cover' />
      <div className='uppercase text-center text-lg font-bold text-blueGray-600 mb-4'>
        <div className='mb-1'>Account Performance</div>
        <span className='badge-tosca text-sm'>
          {performance[0]?.date} ~ {performance[performance?.length - 1]?.date}
        </span>
      </div>

      <div className='amount'>
        <div className='uppercase text-md font-bold text-emerald-500 end-amount'>
          CURRENT : $
          {dollarUSLocale.format(performance[performance?.length - 1]?.value)}
        </div>
        <div className='uppercase text-md font-bold text-emerald-500 min-amount'>
          MIN : ${minValue?.value ? dollarUSLocale.format(minValue?.value) : 0}
        </div>
        <div className='uppercase text-md font-bold text-emerald-500 max-amount'>
          MAX : ${maxValue?.value ? dollarUSLocale.format(maxValue?.value) : 0}
        </div>
      </div>
      <ResponsiveContainer>
        <LineChart
          width={"100%"}
          height={400}
          data={performance}
          margin={{ top: 5, right: 20, left: 20, bottom: 5 }}>
          <Tooltip content={<CustomTooltip />} />
          <Line
            type='linear'
            dataKey='value'
            stroke='rgba(16, 185, 129, 1)'
            strokeWidth={2}
            dot={false}
          />
          <YAxis domain={[minValue?.value, "dataMax"]} hide />
        </LineChart>
      </ResponsiveContainer>

      <div className='options'>
        <div
          className={`select ${option === "1W" && "active"}`}
          onClick={() => setOption("1W")}>
          1W
        </div>
        <div
          className={`select ${option === "1M" && "active"}`}
          onClick={() => setOption("1M")}>
          1M
        </div>
        <div
          className={`select ${option === "3M" && "active"}`}
          onClick={() => setOption("3M")}>
          3M
        </div>
        <div
          className={`select ${option === "6M" && "active"}`}
          onClick={() => setOption("6M")}>
          6M
        </div>
        <div
          className={`select ${option === "YTD" && "active"}`}
          onClick={() => setOption("YTD")}>
          YTD
        </div>
        <div
          className={`select ${option === "1Y" && "active"}`}
          onClick={() => setOption("1Y")}>
          1Y
        </div>
        <div
          className={`select ${option === "All" && "active"}`}
          onClick={() => setOption("All")}>
          All
        </div>
      </div>
    </div>
  );
}
