import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// components
import Admin from "layouts/Admin";
import { sendGetRequest } from "api/helper";
import { Link, useParams } from "react-router-dom";
import MainProfile from "./Tabs/MainProfile";
import Kyc from "./Tabs/Kyc";
import UsdSummary from "./Tabs/UsdSummary";
import IdrSummary from "./Tabs/IdrSummary";
import Notes from "./Tabs/Notes";
import Skeleton from "react-loading-skeleton";

export default function Customer({ color }) {
  const params = useParams();
  const [customer, setCustomer] = useState({});
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(1);

  useEffect(() => {
    setLoading(true);
    async function getData() {
      const result = await sendGetRequest(
        `/api/v1/rms/me/customers/${params.id}`
      );
      setCustomer(result);
      setLoading(false);
    }
    getData();
  }, [params.id]);

  return (
    <Admin title='Customer Management'>
      <div className='flex flex-wrap mt-4'>
        <div className='w-full mb-12 px-4 h-full-80'>
          <div
            className={
              "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
              (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
            }>
            <div className='rounded-t mb-0 py-3 pb-0 border-0 border-b'>
              <div className='flex flex-wrap items-center'>
                <div className='relative w-full max-w-full flex-grow flex-1'>
                  <div className='flex flex-wrap px-4 px-2 items-center justify-between'>
                    <h3
                      className={
                        "font-semibold text-lg mb-3 " +
                        (color === "light" ? "text-blueGray-700" : "text-white")
                      }>
                      {loading ? (
                        <Skeleton height={25} width={"25%"} />
                      ) : (
                        <span className='uppercase font-bold'>
                          {customer?.profile?.firstName}{" "}
                          {customer?.profile?.lastName} |{" "}
                          <small>User ID : {customer?.id} </small>
                          <label className='ml-3'>
                            {customer?.profile?.active ? (
                              <span className='badge-green uppercase text-xs font-bold'>
                                Active
                              </span>
                            ) : (
                              <span className='badge-red uppercase text-xs font-bold'>
                                Inactive
                              </span>
                            )}
                          </label>
                        </span>
                      )}
                    </h3>

                    <Link to='/customer/management'>
                      <button
                        className='mb-4 bg-teal-500 text-white active:bg-lightBlue-600 font-bold uppercase text-sm px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150'
                        type='button'>
                        <i className='fa fa-arrow-left' /> Back to Customer
                        Lists
                      </button>
                    </Link>
                  </div>

                  <div className='bg-blueGray-100 py-2 mb-5'>
                    <MainProfile customer={customer} loading={loading} />
                  </div>

                  <div className='flex px-4'>
                    <div
                      className={`tabs ${tab === 1 && "active"}`}
                      onClick={() => !loading && setTab(1)}>
                      KYC
                    </div>
                    <div
                      className={`tabs ${tab === 2 && "active"}`}
                      onClick={() => !loading && setTab(2)}>
                      Equity (USD)
                    </div>
                    <div
                      className={`tabs ${tab === 3 && "active"}`}
                      onClick={() => !loading && setTab(3)}>
                      Equity (IDR)
                    </div>
                    <div
                      className={`tabs ${tab === 4 && "active"}`}
                      onClick={() => !loading && setTab(4)}>
                      Notes
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='block w-full overflow-x-auto'>
              {tab === 1 && <Kyc customer={customer} />}

              {tab === 2 && <UsdSummary customer={customer} />}

              {tab === 3 && <IdrSummary customer={customer} />}

              {tab === 4 && <Notes customer={customer} />}
            </div>
          </div>
        </div>
      </div>
    </Admin>
  );
}

Customer.defaultProps = {
  color: "light",
};

Customer.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
