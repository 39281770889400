import React, { useCallback, useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  Legend,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Sector,
} from "recharts";
import CustomPieChartLabel from "./CustomPieChartLabel";

export default function CustomerDashboard({ customer }) {
  let dollarUSLocale = Intl.NumberFormat("en-US");

  const [data, setData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);

  const onMouseOver = useCallback((data, index) => {
    setActiveIndex(index);
  }, []);
  const onMouseLeave = useCallback((data, index) => {
    setActiveIndex(null);
  }, []);

  useEffect(() => {
    const array = [];
    if (customer?.equityUsd?.stocks?.breakdown?.length > 0) {
      if (customer?.equityUsd?.cash?.cashBalance > 0) {
        array.push({
          name: "CASH",
          value: Number(customer?.equityUsd?.cash?.cashBalance),
          returnValue: Number(customer?.equityUsd?.cash?.percentageOfEquity),
          returnDayPercent: "-%",
        });
      }

      customer?.equityUsd?.stocks?.breakdown?.forEach((item) => {
        array.push({
          name: item.ticker,
          value: Number(item.marketValue),
          returnValue: Number(item.unrealizedPL),
          returnDayPercent: Number(item.unrealizedDayPLPercent),
        });
      });

      setData(array);
    }
  }, [customer]);

  const COLORS = [
    "#38a3a5",
    "#b1a7a6",
    "#8884d8",
    "#ee45ac",
    "rgba(59, 130, 246, 0.5)",
    "#b1a7a6",
    "rgba(59, 130, 246, 0.8)",
    "#8884d8",
    "rgba(59, 130, 246, 0.6)",
    "#ee45ac",
    "rgba(59, 130, 246, 0.3)",
    "#ee45ac",
    "#38a3a5",
    "#b1a7a6",
    "#8884d8",
  ];

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, midAngle } =
      props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius - 260) * cos;
    const sy = cy + (outerRadius - 260) * sin;
    return (
      <Sector
        cx={sx}
        cy={sy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill='red'
      />
    );
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className='tooltip-chart'>
          Name : {payload[0]?.name}
          <br />
          Value : USD {dollarUSLocale.format(payload[0]?.value) || "-"}
          <br />
          Return Value : USD{" "}
          {dollarUSLocale.format(payload[0]?.payload?.returnValue) || "-"}
          <br />
          {payload[0]?.payload?.returnDayPercent !== "-%" && (
            <>
              Return Day Percent :{" "}
              {payload[0]?.payload?.returnDayPercent !== "-%"
                ? dollarUSLocale.format(payload[0]?.payload?.returnDayPercent) +
                  "%"
                : "-%"}
            </>
          )}
        </div>
      );
    }
  };

  return (
    <div className='flex flex-wrap mt-5 border-b pb-4'>
      <div className='w-full lg:w-12/12 mb-12 xl:mb-0 px-6 pb-6'>
        <div style={{ width: "100%", height: 650, position: "relative" }}>
          <ResponsiveContainer>
            <PieChart>
              <Pie
                dataKey='value'
                nameKey='name'
                data={data}
                label={<CustomPieChartLabel customer={customer} />}
                labelLine
                innerRadius={200}
                outerRadius={260}
                activeShape={renderActiveShape}
                activeIndex={activeIndex}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}>
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Legend
                wrapperStyle={{
                  position: "relative",
                  marginTop: "0",
                }}
              />
              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
}
