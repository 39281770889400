/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/gotrade.png";

import NotificationDropdown from "components/Dropdowns/NotificationDropdown.js";
import UserDropdown from "components/Dropdowns/UserDropdown.js";
import { useSelector } from "react-redux";
import { selectProfile } from "store/slice/auth";

export default function Sidebar() {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const { profile } = useSelector(selectProfile);

  return (
    <nav className='md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-3 px-3'>
      <div className='md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto'>
        {/* Toggler */}
        <button
          className='cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent'
          type='button'
          onClick={() => setCollapseShow("bg-white m-2 py-1 px-6")}>
          <i className='fas fa-bars'></i>
        </button>
        {/* Brand */}
        <Link
          className='md:block text-left text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-2 px-0'
          to='/'>
          <img src={Logo} width={"70%"} />
        </Link>

        {/* User */}
        <ul className='md:hidden items-center flex flex-wrap list-none'>
          <li className='inline-block relative'>
            <NotificationDropdown />
          </li>
          <li className='inline-block relative'>
            <UserDropdown />
          </li>
        </ul>
        {/* Collapse */}
        <div
          className={
            "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-0 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
            collapseShow
          }>
          {/* Collapse header */}
          <div className='md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200'>
            <div className='flex flex-wrap'>
              <div className='w-6/12'>
                <Link
                  className='md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0'
                  to='/'>
                  Go PULSE
                </Link>
              </div>
              <div className='w-6/12 flex justify-end'>
                <button
                  type='button'
                  className='cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent'
                  onClick={() => setCollapseShow("hidden")}>
                  <i className='fas fa-times'></i>
                </button>
              </div>
            </div>
          </div>
          {/* Form */}
          <form className='mt-6 mb-4 md:hidden'>
            <div className='mb-3 pt-0'>
              <input
                type='text'
                placeholder='Search'
                className='border-0 px-3 py-2 h-12 border border-solid  border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal'
              />
            </div>
          </form>

          {/* Divider */}
          <hr className='my-4 md:min-w-full' />

          <h6 className='md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline'>
            Dashboard
          </h6>

          <ul className='md:flex-col md:min-w-full flex flex-col list-none md:mb-4'>
            {profile?.permissions?.find(
              (item) => item?.permission === "read:manager_rms_metrics"
            ) && (
              <li className='items-center'>
                <Link
                  className={
                    "text-xs uppercase pb-4 font-bold block " +
                    (window.location.href.indexOf("/manager/dashboard") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to='/manager/dashboard'>
                  <i
                    className={
                      "fa fa-chart-bar mr-2 text-sm " +
                      (window.location.href.indexOf("/manager/dashboard") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }></i>{" "}
                  Manager Dashboard
                </Link>
              </li>
            )}

            {profile?.permissions?.find(
              (item) => item?.permission === "read:rm_metrics"
            ) && (
              <li className='items-center'>
                <Link
                  className={
                    "text-xs uppercase pb-4 font-bold block " +
                    (window.location.href.indexOf("/individual/dashboard") !==
                    -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to='/individual/dashboard'>
                  <i
                    className={
                      "fa fa-chart-line mr-2 text-sm " +
                      (window.location.href.indexOf("/individual/dashboard") !==
                      -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }></i>{" "}
                  Dashboard
                </Link>
              </li>
            )}

            {profile?.permissions?.find(
              (item) => item?.permission === "read:user_notes"
            ) && (
              <li className='items-center'>
                <Link
                  className={
                    "text-xs uppercase py-0 font-bold block " +
                    (window.location.href.indexOf("/notes") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to='/notes'>
                  <i
                    className={
                      "fa fa-file ml-1 mr-2 text-sm " +
                      (window.location.href.indexOf("/notes") !== -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }
                  />{" "}
                  Notes
                </Link>
              </li>
            )}
          </ul>

          {profile?.permissions?.find(
            (item) => item?.permission === "read:users"
          ) && (
            <>
              {/* Divider */}
              <hr className='my-4 mt-2 md:min-w-full' />
              {/* Heading */}
              <h6 className='md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline'>
                Account Management
              </h6>
              {/* Navigation */}
            </>
          )}

          <ul className='md:flex-col md:min-w-full flex flex-col list-none md:mb-4'>
            {profile?.permissions?.find(
              (item) => item?.permission === "read:rm_customers"
            ) && (
              <li className='items-center'>
                <Link
                  className={
                    "text-xs uppercase pb-4 font-bold block " +
                    (window.location.href.indexOf("/customer/management") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to='/customer/management'>
                  <i
                    className={
                      "fa fa-users mr-2 text-sm " +
                      (window.location.href.indexOf("/customer/management") !==
                      -1
                        ? "opacity-75"
                        : "text-blueGray-300")
                    }></i>{" "}
                  Customers
                </Link>
              </li>
            )}

            <li className='items-center' style={{ display: "none" }}>
              <Link
                className={
                  "text-xs uppercase py-0 font-bold block " +
                  (window.location.href.indexOf("/schedule/management") !== -1
                    ? "text-lightBlue-500 hover:text-lightBlue-600"
                    : "text-blueGray-700 hover:text-blueGray-500")
                }
                to='/schedule/management'>
                <i
                  className={
                    "fa fa-calendar-alt mr-3 text-sm " +
                    (window.location.href.indexOf("/schedule/management") !== -1
                      ? "opacity-75"
                      : "text-blueGray-300")
                  }
                />{" "}
                Schedule Management
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
